import { RemovableCardForm } from "@app/components/ui/RemovableCardForm/RemovableCardForm";
import {
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
} from "@app/components/ui/RemovableCardForm/RemovableCardForm.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { useLoadingPopup } from "@app/hooks/useLoadingPopup";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import { MachineDef, TempMachineDef } from "@app/types/machines.types";
import { CardLayout } from "@layouts/CardLayout/CardLayout";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { v4 } from "uuid";

export const EquipmentAndMachinery = () => {
  const savedMachines = useSelector((state: RootState) => state.machines.machines);
  const loading = useLoadingPopup(ModelNames.MACHINES);
  const [innerMachines, setInnerMachines] = useState<MachineDef[]>(savedMachines);
  const [unsavedMachines, setUnsavedMachines] = useState<TempMachineDef[]>([]);
  const [data, setData] = useState<Array<MachineDef | TempMachineDef>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch } = store;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!loading) {
      setInnerMachines(savedMachines);
    }
  }, [savedMachines, loading]);

  useDeepCompareEffect(() => {
    setData([...innerMachines, ...unsavedMachines]);
  }, [innerMachines, unsavedMachines]);

  const options: RemovableCardFormInputOptions<MachineDef>[] = [
    {
      inputType: RemovableCardFormInputTypes.uploader,
      required: false,
      description: t("Images"),
      value: "medias",
    },
    {
      inputType: RemovableCardFormInputTypes.textArea,
      required: true,
      description: t("Brief description"),
      value: "title",
      placeholder: "CAT 9T Excavator",
    },
    {
      inputType: RemovableCardFormInputTypes.textField,
      required: true,
      description: t("Type"),
      value: "type",
      placeholder: "Mini excavator",
    },
    {
      inputType: RemovableCardFormInputTypes.textField,
      required: true,
      placeholder: "Caterpillar",
      description: t("Manufacturer"),
      value: "make",
    },
    {
      inputType: RemovableCardFormInputTypes.textField,
      required: true,
      placeholder: "308 CR Short",
      description: t("Model"),
      value: "model",
    },
    {
      inputType: RemovableCardFormInputTypes.numberTextField,
      required: false,
      description: t("Year of manufacture"),
      placeholder: "2022",
      value: "yearOfManufacture",
    },
  ];

  function addMachine() {
    const machine: TempMachineDef = {
      id: `temp-${v4()}`,
      medias: [],
      title: "",
      type: "",
      make: "",
      model: "",
      isTemp: true,
    };
    setUnsavedMachines([...unsavedMachines, machine]);
  }

  async function removeMachine(machine: MachineDef | TempMachineDef) {
    if ("isTemp" in machine) {
      const index = unsavedMachines.findIndex((m) => m.id === machine.id);
      if (index > -1) {
        const splicedOut = [...unsavedMachines];
        splicedOut.splice(index, 1);
        setUnsavedMachines(splicedOut);
      }
    } else {
      await dispatch.machines.removeMachineAction(machine.id);
    }
  }

  async function saveMachine(machine: MachineDef | TempMachineDef) {
    const newMachine = {
      ...machine,
      lang: convertLangForBackend(i18n.language),
    };
    setIsSaving(true);
    if ("isTemp" in newMachine) {
      const index = unsavedMachines.findIndex((m) => m.id === newMachine.id);
      delete newMachine.isTemp;
      if (index > -1) {
        await dispatch.machines.addMachineAction(newMachine);
        const newArr = [...unsavedMachines];
        newArr.splice(index, 1);
        setUnsavedMachines(newArr);
      }
    } else {
      await dispatch.machines.updateMachineAction(newMachine);
    }
    setIsSaving(false);
  }

  const changeImages = (machine: MachineDef | TempMachineDef, newImages: string[]) => {
    const newMachine = { ...machine };
    newMachine.medias = newImages;
    if ("isTemp" in machine) {
      const index = unsavedMachines.findIndex((m) => m.id === machine.id);
      const newState = [...unsavedMachines];
      newState.splice(index, 1, newMachine);
      setUnsavedMachines(newState);
    } else {
      const index = innerMachines.findIndex((m) => m.id === machine.id);
      const newState = [...innerMachines];
      newState.splice(index, 1, newMachine);
      setInnerMachines(newState);
    }
  };

  return (
    <ContentLayout
      title={t("Show your equipment and machinery")}
      description={t(
        "Almost the most important part for any craftsman. Many employees are looking at the heaviest and newest models in your company's fleet. This information is often a decision criterion for potential new employees."
      )}
      nextRoute={RoutePaths.EMPLOYEE_BENEFITS}
    >
      <CardLayout
        onAddMore={addMachine}
        addMoreTitle={t("Add more machines")}
        loading={isSaving}
        data={data}
        renderData={(item) => {
          if ("isTemp" in item) {
            return (
              <RemovableCardForm
                options={options}
                onRemove={removeMachine}
                onSave={saveMachine}
                initialValues={item}
                onImagesChange={(images) => changeImages(item, images)}
              />
            );
          }
          return (
            <RemovableCardForm
              options={options}
              onRemove={removeMachine}
              onSave={saveMachine}
              initialValues={item}
              onImagesChange={(images) => changeImages(item, images)}
            />
          );
        }}
      />
    </ContentLayout>
  );
};
