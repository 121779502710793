export enum EEngagementType {
  APPLIED = "applied",
  CONTACTED = "contacted",
  CAMPAIGN_RECOMMENDED = "campaign-recommended",
  APP_RECOMMENDED = "app-recommended",
  MANUALLY_RECOMMENDED = "manually-recommended",
}

export interface EngagementSAColumnsDataDef {
  key: string;
  companyName: JSX.Element;
  status: JSX.Element;
}

export type EngagementSADef = {
  id: string;
  workerProfileId: string;
  crafthuntCompanyId: string;
  engagementType: EEngagementType;
  createdAt: string;
};
