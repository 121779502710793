import { ApiProjectResponse, PostProjectDef, UpdateProjectDef } from "@app/types/projects.types";
import moment from "moment-timezone";

export const mapFormDataToApi = (data: PostProjectDef | UpdateProjectDef) => {
  return {
    ...data,
    address: data.address?.address,
    coordinate: data.address?.coordinates
      ? {
          lat: parseFloat(data.address.coordinates.lat.toString()),
          lng: parseFloat(data.address.coordinates.lng.toString()),
        }
      : undefined,
  };
};

export const mapResponseToFormData = (data: ApiProjectResponse) => {
  return {
    ...data,
    starts: data.starts ? moment(data.starts) : null,
    ends: data.ends ? moment(data.ends) : null,
    coverImages: data.coverImages?.map((m) => m.url) || [],
    tagTypeIds: data.tagTypes?.map((i) => i.id),
    address: { address: data.address, coordinates: data.coordinate },
  };
};
