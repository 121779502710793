import { UploadedMedia } from "@app/types/media.types";
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import { v4 as uuidV4 } from "uuid";

export function useFirebaseStorage() {
  const getStorageRef = (fileName: string): StorageReference => {
    const firebaseStorage = getStorage();
    const uuid = uuidV4();
    return ref(firebaseStorage, `${Date.now()}-${uuid}-${fileName}`);
  };

  const parseMediaObject = async (
    fileName: string,
    media: StorageReference,
    isFile = false
  ): Promise<UploadedMedia> => {
    const url = await getDownloadURL(media);
    const metadata = await getMetadata(media);
    let type = "";
    if (metadata.contentType?.includes("image") && !isFile) {
      type = "image";
    } else if (metadata.contentType?.includes("video") && !isFile) {
      type = "video";
    } else {
      type = "file";
    }
    return {
      fileName,
      url,
      type,
    };
  };

  const uploadSomething = async (file: File | Blob, fileName: string, isFile = false) => {
    const mediaRef = getStorageRef(fileName);
    const uploadTask = uploadBytes(mediaRef, file, {
      customMetadata: {
        platform: "web/crafthunt-admin-panel",
      },
    });
    try {
      await uploadTask;
      return parseMediaObject(fileName, mediaRef, isFile);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const uploadPhoto = (file: File | Blob, fileName: string) =>
    uploadSomething(file, fileName, false);
  const uploadDocument = (file: File | Blob, fileName: string) =>
    uploadSomething(file, fileName, true);

  return {
    uploadPhoto,
    uploadDocument,
  };
}
