import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./sources/en.json";
import enShared from "./shared/en.json";
import es from "./sources/es.json";
import esShared from "./shared/es.json";
import de from "./sources/de.json";
import deShared from "./shared/de.json";
import da from "./sources/da.json";
import daShared from "./shared/da.json";
import it from "./sources/it.json";
import itShared from "./shared/it.json";
import fr from "./sources/fr.json";
import frShared from "./shared/fr.json";
import pl from "./sources/pl.json";
import plShared from "./shared/pl.json";
import ro from "./sources/ro.json";
import roShared from "./shared/ro.json";
import ptBR from "./sources/pt_BR.json";
import ptBRShared from "./shared/pt_BR.json";
import cs from "./sources/cs.json";
import csShared from "./shared/cs.json";
import { isDev } from "@app/constants/env";
import { getSavedLanguage, saveLanguage } from "@app/helpers/language.helper";

const translations = {
  en: {
    translation: { ...en, ...enShared },
  },
  es: {
    translation: { ...es, ...esShared },
  },
  de: {
    translation: { ...de, ...deShared },
  },
  da: {
    translation: { ...da, ...daShared },
  },
  it: {
    translation: { ...it, ...itShared },
  },
  fr: {
    translation: { ...fr, ...frShared },
  },
  pl: {
    translation: { ...pl, ...plShared },
  },
  ro: {
    translation: { ...ro, ...roShared },
  },
  pt_BR: {
    translation: { ...ptBR, ...ptBRShared },
  },
  cs: {
    translation: { ...cs, ...csShared },
  },
};

i18n.use(initReactI18next).init({
  resources: translations,
  lng: getSavedLanguage() || "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
  parseMissingKeyHandler: (key: string) => {
    if (isDev()) {
      return key + " ⚠️ MISSING TRANSLATION ⚠️ ";
    }
    return key;
  },
});
i18n.on("languageChanged", (lang: string) => {
  saveLanguage(lang);
});

export default i18n;
