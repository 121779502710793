import { useQuery } from "@tanstack/react-query";
import { GetJobAdsSAParamsDef, JobAdsSAWithPaginationDef } from "../types/super-admin.job.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getJobAdsSA = (params: GetJobAdsSAParamsDef) => {
  return superAdminApi.get<JobAdsSAWithPaginationDef>("/super_admin/jobs", {
    params,
  });
};

export const useJobsSA = (params: GetJobAdsSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "jobs", params] as const,
    queryFn: ({ queryKey }) => getJobAdsSA(queryKey[3]).then(({ data }) => data),
  });
