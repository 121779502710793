import { LanguagePicker } from "@app/components/ui/LanguagePicker/LanguagePicker";
import { Logo } from "@app/components/ui/Logo/Logo";
import { Colors } from "@app/constants/colors.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState } from "@app/store/store";
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AuthCarousel } from "./AuthCarousel/AuthCarousel";
import styles from "./AuthLayout.module.scss";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Login } from "./Login/Login";
import { Signup } from "./Signup/Signup";

export const AuthLayout = () => {
  const location = useLocation();
  const token = useSelector((state: RootState) => state.auth.token);

  if (token) {
    return <Navigate to={RoutePaths.DASHBOARD} />;
  }

  return (
    <Content
      className={styles.container}
      style={{
        backgroundColor: Colors.greyBackground,
      }}
    >
      <LanguagePicker isFixed />

      <Row className={styles.row} justify="end">
        <Col span={24} lg={10} xl={9} xxl={7} className={styles.content}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {
            {
              [RoutePaths.SIGN_UP]: <Signup />,
              [RoutePaths.LOGIN]: <Login />,
              [RoutePaths.FORGOT_PASSWORD]: <ForgotPassword />,
            }[location.pathname]
          }
        </Col>
        <Col span={24} lg={14} xl={15}>
          <AuthCarousel />
        </Col>
      </Row>
    </Content>
  );
};
