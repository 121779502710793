import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CrafthuntBauGPTChat } from "./CrafthuntBauGPTChat";
import styles from "./BauGPT.module.scss";

export const BauGPT = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { i18n } = useTranslation();
  const currentTranslations = i18n.getDataByLanguage(i18n.language);

  if (!token || !currentTranslations?.translation) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.container}>
      <CrafthuntBauGPTChat
        jwt={token}
        translations={currentTranslations.translation}
        lang={convertLangForBackend(i18n.language)}
      />
    </div>
  );
};
