import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { mapAdminUserToApi } from "@app/features/super-admin/helpers/super-admin.helper";
import { AdminUserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { Col, Divider, Form, Input, Row, Switch, message } from "antd";
import { Rule } from "antd/lib/form";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";

type CompanyAdminInfoFormProps = {
  form: FormInstance<AdminUserFormDef>;
  selectedUser?: AdminUserFormDef;
  onSave: () => void;
};

export const CompanyAdminInfoForm = ({ form, selectedUser, onSave }: CompanyAdminInfoFormProps) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  // loading states
  const loading = useSelector((state: RootState) => state.loading.effects.superAdmin.updateUserSA);
  // data
  const emailValue = useWatch("email", form);
  const phoneValue = useWatch("phone", form);
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };

  const onSubmitForm = async (values: AdminUserFormDef) => {
    if (selectedUser) {
      try {
        await dispatch.superAdmin.updateUserSA({
          ...mapAdminUserToApi(values),
          id: selectedUser.id,
          lang: convertLangForBackend(i18n.language),
        });
        onSave();
        message.success({ content: t("Saved!"), key: "user-info" });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-info",
            duration: 10,
          });
        }
      }
    }
  };

  const onFormFailed = () => {
    message.error({ content: t("Please fix invalid fields"), key: "user-info" });
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      labelAlign="right"
      form={form}
      initialValues={selectedUser}
      onFinish={onSubmitForm}
      onFinishFailed={onFormFailed}
      data-hs-do-not-collect="true"
    >
      {loading && <LoadingSpinner />}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label="ID" name="id">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={t("First name")} name={"firstName"} rules={[requiredRule]}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Last name")}
            name={"lastName"}
            rules={[{ ...requiredRule, whitespace: false }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Email")}
            name={"email"}
            rules={[
              {
                type: "email",
                required: !phoneValue,
                whitespace: true,
                message: t("Required"),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Phone number")}
            name={"phone"}
            rules={[
              {
                type: "string",
                required: !emailValue,
                whitespace: true,
                message: t("Required"),
              },
              {
                validator: (_, value) => {
                  if (value && !isPossiblePhoneNumber(value)) {
                    return Promise.reject(t("Not valid phone number"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneNumberInput placeholder={t("Enter phone number")} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Reviewed")}
            name={"isReviewed"}
            valuePropName="checked"
            help={t(
              "This determines whether the user is shown in explore page and will see jobs (turned on), or hidden in explore page and no jobs (turned off)."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
