import { LANGUAGES_PHONE } from "@app/constants/languages.constants";
import { getDefaultCountry } from "@app/helpers/language.helper";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PhoneInput, { Value } from "react-phone-number-input";
import cz from "react-phone-number-input/locale/cz.json";
import de from "react-phone-number-input/locale/de.json";
import es from "react-phone-number-input/locale/es.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import pl from "react-phone-number-input/locale/pl.json";
import ptBR from "react-phone-number-input/locale/pt-BR.json";
import "react-phone-number-input/style.css";
import styles from "./PhoneNumberInput.module.scss";

type PhoneNumberInputProps = {
  value?: string;
  onChange?: (value: Value) => void;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
};

export const PhoneNumberInput = ({
  value,
  onChange,
  placeholder,
  name,
  disabled,
}: PhoneNumberInputProps) => {
  const { i18n } = useTranslation();

  const handleOnChange = (newValue: Value) => {
    onChange?.(newValue);
  };

  const getLabels = () => {
    switch (i18n.language.toLowerCase()) {
      case "de":
        return de;
      case "pt_br":
        return ptBR;
      case "pl":
        return pl;
      case "it":
        return it;
      case "es":
        return es;
      case "cs":
        return cz;
      case "fr":
        return fr;
      default:
        return undefined;
    }
  };

  return (
    <PhoneInput
      defaultCountry={getDefaultCountry(i18n.language)}
      countries={LANGUAGES_PHONE}
      labels={getLabels()}
      international
      countryCallingCodeEditable={false}
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
      className={clsx(
        styles.container,
        "ant-input-affix-wrapper ant-input-affix-wrapper-has-feedback ant-input-affix-wrapper-lg",
        disabled && styles.disabled
      )}
      numberInputProps={{ className: styles.phone }}
      name={name}
      disabled={disabled}
    />
  );
};
