export enum RemovableCardFormInputTypes {
  select = "Select",
  /** Single-line input field */
  textField = "textField",
  /** Multi-line input area */
  textArea = "textArea",
  emailField = "emailField",
  phoneNumberField = "phoneNumberField",
  numberTextField = "numberTextField",
  datePicker = "datePicker",
  checkbox = "checkbox",
  uploader = "uploader",
  address = "address",
}

export type RemovableCardFormInputBase<T> = {
  required: boolean;
  description: string;
  placeholder?: string;
  value: Exclude<keyof T, "id">;
  hideLabel?: boolean;
};

export interface RemovableCardFormSelectInput<T> extends RemovableCardFormInputBase<T> {
  inputType: RemovableCardFormInputTypes.select;
  isMultiple?: boolean;
  sortAlphabetically?: boolean;
  choices?: {
    text: string;
    value: string | number;
  }[];
  /** Add key that will enable the select, otherwise it will be disabled - (default: ignored, always enabled) */
  enabledBy?: string;
}

export interface RemovableCardFormStringInput<T> extends RemovableCardFormInputBase<T> {
  inputType: Omit<
    RemovableCardFormInputTypes,
    | RemovableCardFormInputTypes.numberTextField
    | RemovableCardFormInputTypes.checkbox
    | RemovableCardFormInputTypes.uploader
    | RemovableCardFormInputTypes.select
    | RemovableCardFormInputTypes.datePicker
    | RemovableCardFormInputTypes.address
  >;
}

export interface RemovableCardFormNumberInput<T> extends RemovableCardFormInputBase<T> {
  inputType: RemovableCardFormInputTypes.numberTextField;
  min?: number;
  max?: number;
}

export interface RemovableCardFormCheckboxInput<T> extends RemovableCardFormInputBase<T> {
  inputType: RemovableCardFormInputTypes.checkbox;
}

export interface RemovableCardFormDatePickerInput<T> extends RemovableCardFormInputBase<T> {
  inputType: RemovableCardFormInputTypes.datePicker;
  shouldBeBefore?: Exclude<keyof T, "id">;
  shouldBeAfter?: Exclude<keyof T, "id">;
}

export interface RemovableCardFormUploaderInput<T> extends RemovableCardFormInputBase<T> {
  inputType: RemovableCardFormInputTypes.uploader;
}

export type RemovableCardFormInputOptions<T> =
  | RemovableCardFormStringInput<T>
  | RemovableCardFormNumberInput<T>
  | RemovableCardFormCheckboxInput<T>
  | RemovableCardFormUploaderInput<T>
  | RemovableCardFormSelectInput<T>
  | RemovableCardFormDatePickerInput<T>;

export type ConditionalFieldRequirement<T> = {
  propertyName: Exclude<keyof T, "id">;
  displayName: string;
};
