import { generateShortLink } from "@app/api/short-link.api";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { DateFormats } from "@app/constants/date.constants";
import { ENV, isDev } from "@app/constants/env";
import { useUsersSA } from "@app/features/super-admin/api/super-admin.user.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import { UsersSAResponseDef } from "@app/features/super-admin/types/super-admin.user.types";
import { RootState } from "@app/store/store";
import { AddressObjDef } from "@app/types/address.types";
import {
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Table,
  TableColumnsType,
  message,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EngagementModal } from "./components/EngagementModal/EngagementModal";
import { useUserModalGlobal } from "./components/UserModal/UserModalGlobal";

const { Search } = Input;

const LINK_KEY = "link";

export const Users = () => {
  const { t } = useTranslation();
  // table params
  const [keyword, setKeyword] = useState("");
  const [selectedTradeIds, setSelectedTradeIds] = useState([]);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState([]);
  const [coordinates, setCoordinates] = useState("");
  const [page, setPage] = useState(0);
  // filter data
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  // modals
  const { setUserId } = useUserModalGlobal();
  const [recommendationModalOpen, setRecommendationModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ReturnType<typeof mapUserToForm>>();

  const usersQuery = useUsersSA({
    keyword,
    tradeIds: selectedTradeIds,
    jobTitleIds: selectedJobTitleIds,
    coordinates: coordinates,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
  });

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setPage(pagination.current - 1);
    }
  };

  const handleAddressChange = (address: AddressObjDef | null) => {
    const newCoords = address?.coordinates
      ? `${address.coordinates.lat},${address.coordinates.lng}`
      : "";

    if (newCoords !== coordinates) {
      setPage(0);
    }

    setCoordinates(newCoords);
  };

  const copyToClipboard = (value: string) => {
    try {
      navigator.clipboard.writeText(value);
      message.success({ content: t("Link copied!"), key: LINK_KEY, duration: 0.5 });
    } catch (err) {
      Modal.error({
        title: "Something went wrong, here is the raw link",
        content: value,
      });
      message.destroy(LINK_KEY);
    }
  };

  const generateShortMagicLink = async (magicLink: string) => {
    try {
      message.loading({ content: "Preparing magic link", key: LINK_KEY, duration: 0 });
      const shortMagicLink = isDev() ? magicLink : await generateShortLink(magicLink);
      copyToClipboard(shortMagicLink);
    } catch (error) {
      Modal.error({
        title: "Something went wrong, here is the raw link",
        content: magicLink,
      });
      message.destroy(LINK_KEY);
    }
  };

  const generatedData = (data: UsersSAResponseDef[]) => {
    return data.map((item) => {
      const hubspotUrl =
        item.hubspotContactId &&
        `https://app-eu1.hubspot.com/contacts/26849627/contact/${item.hubspotContactId}`;

      return {
        key: item.id,
        name: [item.firstName, item.lastName].filter(Boolean).join(" "),
        email: item.email || "",
        phone: item.phone || "",
        links: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button onClick={() => generateShortMagicLink(item.magicLink)} type="link">
              Magic
            </Button>
            <Button
              onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.slug}`)}
              type="link"
            >
              Profile
            </Button>
            <Button
              onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.anonymousSlug}`)}
              type="link"
            >
              Anonymous
            </Button>
            {hubspotUrl && (
              <Button href={hubspotUrl} type="link" target="_blank">
                Hubspot
              </Button>
            )}
          </div>
        ),
        isVerified: (
          <Badge
            key="isVerified"
            status={item.isVerified ? "success" : "default"}
            text={t(item.isVerified ? "Yes" : "No")}
          />
        ),
        isCompanyAdmin: (
          <Badge
            key="isCompanyAdmin"
            status={item.isCompanyAdmin ? "success" : "default"}
            text={t(item.isCompanyAdmin ? "Yes" : "No")}
          />
        ),
        distance:
          typeof item.distance === "number"
            ? `${item.distance.toFixed(1).replace(".0", "")} km`
            : "-",
        createdAt: moment(item.createdAt).format(DateFormats.FULL_DATE_TIME),
        operation: (
          <Space size="middle">
            <a onClick={() => setUserId(item.id)}>{t("Edit")}</a>
            <a
              onClick={() => {
                setRecommendationModalOpen(true);
                setSelectedUser(mapUserToForm(item));
              }}
            >
              {t("recommend-button")}
            </a>
          </Space>
        ),
      };
    });
  };

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 220,
    },
    { title: t("Email"), dataIndex: "email", key: "email", width: 150 },
    { title: t("Mobile phone"), dataIndex: "phone", key: "phone", width: 160 },
    {
      title: t("company-profile-links-title"),
      dataIndex: "links",
      key: "links",
      width: 150,
      align: "center",
    },
    { title: t("Verified"), dataIndex: "isVerified", key: "isVerified", width: 110 },
    {
      title: t("Company Admin"),
      dataIndex: "isCompanyAdmin",
      key: "isCompanyAdmin",
      width: 120,
    },
    { title: t("distance-label"), dataIndex: "distance", key: "distance", width: 150 },
    { title: t("Created"), dataIndex: "createdAt", key: "createdAt" },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 200 },
  ];

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for id, name, email, phone, slug, anonymousSlug and city")}
          enterButton={t("Search")}
          size="large"
          onSearch={(value) => {
            setKeyword(value);
            setPage(0);
          }}
          allowClear
        />
        <Row gutter={[16, 16]} align="middle">
          <Col span={8}>
            <AddressPicker
              type={EAddressPickerType.CITY}
              disableMap
              onChange={handleAddressChange}
            />
          </Col>
          <Col span={8}>
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("roles-select")}
              options={jobTitleChoices}
              onChange={(value) => {
                setPage(0);
                setSelectedJobTitleIds(value);
              }}
            />
          </Col>
          <Col span={8}>
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Trades")}
              options={tradesChoices}
              onChange={(value) => {
                setPage(0);
                setSelectedTradeIds(value);
              }}
            />
          </Col>
        </Row>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={generatedData(usersQuery.data?.data ?? [])}
          loading={usersQuery.isLoading}
          pagination={{
            current: page + 1,
            total: usersQuery.data?.count,
            showTotal: (total) => `${t("Total")} ${total} ${t("Users")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
            position: ["bottomLeft"],
          }}
          onChange={handlePageChange}
          scroll={{ x: 1500 }}
        />
      </Space>
      <EngagementModal
        open={recommendationModalOpen}
        selectedUserId={selectedUser?.id}
        onClose={() => setRecommendationModalOpen(false)}
      />
    </>
  );
};
