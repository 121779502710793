import { auth } from "@app/features/auth/models/auth.model";
import { superAdmin } from "@app/features/super-admin/models/superAdmin.model";
import { Models } from "@rematch/core";
import { candidates } from "./candidates.model";
import { companyData } from "./companyData.model";
import { companyInfo } from "./companyInfo.model";
import { integrationJobs } from "./integrationJobs.model";
import { jobAds } from "./jobAds.model";
import { machines } from "./machines.model";
import { projects } from "./projects.model";
import { tags } from "./tags.model";
import { trades } from "./trades.model";
import { userSettings } from "./userSettings.model";

export interface RootModel extends Models<RootModel> {
  companyData: typeof companyData;
  companyInfo: typeof companyInfo;
  userSettings: typeof userSettings;
  auth: typeof auth;
  tags: typeof tags;
  machines: typeof machines;
  projects: typeof projects;
  trades: typeof trades;
  jobAds: typeof jobAds;
  candidates: typeof candidates;
  superAdmin: typeof superAdmin;
  integrationJobs: typeof integrationJobs;
}

export enum ModelNames {
  COMPANY_DATA = "companyData",
  AUTH = "auth",
  TAGS = "tags",
  MACHINES = "machines",
  PROJECTS = "projects",
  COMPANY_INFO = "companyInfo",
  TRADES = "trades",
  JOB_ADS = "jobAds",
  CANDIDATES = "candidates",
  SUPER_ADMIN = "superAdmin",
  INTEGRATION_JOBS = "integrationJobs",
}

export const models: RootModel = {
  companyData,
  companyInfo,
  userSettings,
  auth,
  tags,
  machines,
  projects,
  trades,
  jobAds,
  candidates,
  superAdmin,
  integrationJobs,
};
