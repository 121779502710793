import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import { useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import { useQueryClient } from "@tanstack/react-query";
import { parseAsString, useQueryState } from "nuqs";
import { UserModal } from "./UserModal";

export const useUserModalGlobal = () => {
  const [userId, setUserId] = useQueryState("userId", parseAsString);

  return {
    userId,
    setUserId,
  };
};

export const UserModalGlobal = () => {
  const queryClient = useQueryClient();
  const { userId, setUserId } = useUserModalGlobal();

  const userQuery = useUserSA({
    userId: userId,
  });

  return (
    <UserModal
      open={Boolean(userId)}
      isError={userQuery.isError}
      isLoading={userQuery.isLoading}
      selectedUser={userQuery.data ? mapUserToForm(userQuery.data) : undefined}
      onSave={() => {
        queryClient.invalidateQueries({
          queryKey: queryKeysSA.all,
        });
        setUserId(null);
      }}
      onCancel={() => setUserId(null)}
    />
  );
};
