import { sendChatMessage } from "@app/api/chat-messages.api";
import { Kanban } from "@app/features/kanban/components/Kanban/Kanban";
import { RootState, store } from "@app/store/store";
import {
  CandidateDef,
  CandidateRejectionFormDef,
  CompanyCandidateStatusDef,
  ECandidateMethod,
  ECandidateRejectionReason,
  ECandidateStatus,
} from "@app/types/candidate.types";
import { UniqueIdentifier } from "@dnd-kit/core";
import { orderBy, uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SpecialJobSelectValue } from "../../Candidates";
import { triggerEventAcceptRecommendedCandidate } from "../../helpers/candidates-analytics.helper";
import { useGetCandidateName } from "../../hooks/useGetCandidateName";
import { CandidateDrawer } from "../CandidateDrawer/CandidateDrawer";
import { FirstMessageModal } from "../FirstMessageModal/FirstMessageModal";
import { HiringModal } from "../HiringModal/HiringModal";
import { RejectionModal } from "../RejectionModal/RejectionModal";
import { CandidateCardContent } from "./components/CandidateCardContent/CandidateCardContent";
import { ContainerHeader } from "./components/ContainerHeader/ContainerHeader";

type CandidatesKanbanProps = {
  currentJobAdId?: string;
  searchValue: string;
};
export const CandidatesKanban = ({ currentJobAdId, searchValue }: CandidatesKanbanProps) => {
  const { getCandidateName } = useGetCandidateName();
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const [dataKey, setDataKey] = useState(uniqueId);
  const candidates = useSelector((state: RootState) => state.candidates.candidates);
  const { dispatch } = store;
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectedCandidateIsFromRecommended, setRejectedCandidateIsFromRecommended] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorSendFirstMessage, setErrorSendFirstMessage] = useState(false);
  const [nextStatusId, setNextStatusId] = useState<string>();
  const [showHiringModal, setShowHiringModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDef | undefined>();
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);

  const isAllJobSelected =
    currentJobAdId === SpecialJobSelectValue.ALL ||
    currentJobAdId === SpecialJobSelectValue.CONTACTED;

  const containers = useMemo(() => candidateStatuses.map((item) => item.id), [candidateStatuses]);
  const recommendedStatus = useMemo(
    () => candidateStatuses.find((item) => item.status === ECandidateStatus.RECOMMENDED),
    [candidateStatuses]
  );

  const allCandidatesSorted = useMemo(
    () => orderBy(candidates, (candidate) => candidate.createdAt, "desc"),
    [candidates]
  );

  const candidatesMap = useMemo(
    () =>
      new Map<UniqueIdentifier, CandidateDef>(
        allCandidatesSorted.map((candidate) => [candidate.id, candidate])
      ),
    [candidates]
  );

  const candidateIdsByStatus = useMemo(() => {
    return candidateStatuses.reduce((result, status) => {
      result[status.id] = allCandidatesSorted
        .filter((candidate) => {
          const userFullName = getCandidateName(candidate);
          const search = searchValue.toLowerCase();

          const candidateIsMatchingSearch =
            userFullName.toLowerCase().includes(search) ||
            candidate.workerProfile.email?.toLowerCase().includes(search) ||
            candidate.workerProfile.phone?.toLowerCase().includes(search);

          return candidate.companyCandidateStatus.id === status.id && candidateIsMatchingSearch;
        })
        .map((candidate) => candidate.id);
      return result;
    }, {} as Record<string, string[]>);
  }, [candidateStatuses, allCandidatesSorted, searchValue, dataKey]);

  const onAnyModalCancel = () => {
    setDataKey(uniqueId);
  };

  const handleClickCandidate = useCallback((candidate: CandidateDef) => {
    setSelectedCandidate(candidate);
    setShowCandidateDrawer(true);
  }, []);

  const sortFunction = (items: UniqueIdentifier[]) => {
    return allCandidatesSorted.map((candidate) => candidate.id).filter((id) => items.includes(id));
  };

  const saveCandidate = async (
    candidateId: string,
    newStatus: string,
    rejectionMessage?: string,
    rejectionReason?: ECandidateRejectionReason,
    rejectionReasonDescription?: string,
    handleRejection?: boolean
  ) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId,
      candidateData: {
        companyCandidateStatusId: newStatus,
        rejectionMessage,
        rejectionReason,
        rejectionReasonDescription,
        handleRejection,
      },
    });
  };

  const handleOnCancelHire = () => {
    setShowHiringModal(false);
    onAnyModalCancel();
  };

  const handleOnHire = () => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.HIRED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(selectedCandidate?.id, candidateStatus.id);
    }
    setShowHiringModal(false);
  };

  const handleOnCancelReject = () => {
    setShowRejectionModal(false);
    onAnyModalCancel();
  };

  const handleOnReject = (data: CandidateRejectionFormDef) => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.REJECTED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(
        selectedCandidate?.id,
        candidateStatus.id,
        data.rejectionMessage,
        data.rejectionReason,
        data.rejectionReasonDescription,
        !rejectedCandidateIsFromRecommended
      );
    }
    setShowRejectionModal(false);
  };

  const handleOnCancelSendFirstMessage = () => {
    setShowMessageModal(false);
    onAnyModalCancel();
  };

  const handleSendFirstMessage = async (message: string) => {
    try {
      if (nextStatusId && selectedCandidate) {
        await saveCandidate(selectedCandidate?.id, nextStatusId);
        sendChatMessage(message, selectedCandidate.id);
        triggerEventAcceptRecommendedCandidate(selectedCandidate.id);
      }
      setShowMessageModal(false);
    } catch (error) {
      console.error(error);
      setErrorSendFirstMessage(true);
    }
  };

  const handleOnDropped = (containerId: UniqueIdentifier, itemId: UniqueIdentifier) => {
    const candidateStatus = candidateStatuses.find((item) => item.id === containerId);
    const candidate = candidates.find((item) => item.id === itemId);

    if (candidate?.companyCandidateStatus.id === containerId || !candidateStatus || !candidate) {
      return;
    }

    if (
      candidateStatus.status === ECandidateStatus.RECOMMENDED &&
      candidate.companyCandidateStatus.status !== ECandidateStatus.RECOMMENDED
    ) {
      return;
    }

    setSelectedCandidate(candidate);
    if (
      candidateStatus.status !== ECandidateStatus.REJECTED &&
      candidate.companyCandidateStatus.status === ECandidateStatus.RECOMMENDED
    ) {
      setNextStatusId(candidateStatus.id);
      setShowMessageModal(true);
    } else if (candidateStatus.status === ECandidateStatus.REJECTED) {
      setRejectedCandidateIsFromRecommended(
        candidate.companyCandidateStatus.status === ECandidateStatus.RECOMMENDED
      );
      setShowRejectionModal(true);
    } else if (candidateStatus.status === ECandidateStatus.HIRED) {
      setShowHiringModal(true);
    } else {
      saveCandidate(candidate.id, candidateStatus.id);
    }
  };

  useEffect(() => {
    const loadCandidatesForStatus = async (candidateStatus: CompanyCandidateStatusDef) => {
      dispatch.candidates.setCandidateStatusLoading({
        id: candidateStatus.id,
        loading: true,
      });
      const jobAdId = !currentJobAdId || isAllJobSelected ? undefined : [currentJobAdId];
      const method =
        currentJobAdId === SpecialJobSelectValue.CONTACTED ? ECandidateMethod.CONTACTED : undefined;
      await dispatch.candidates.getAllCandidates({
        jobAdIds: jobAdId,
        companyCandidateStatusId: candidateStatus.id,
        method,
      });
      dispatch.candidates.setCandidateStatusLoading({
        id: candidateStatus.id,
        loading: false,
      });
    };

    const loadCandidates = async () => {
      await dispatch.candidates.clearAllCandidatesAction();
      for (const candidateStatus of candidateStatuses) {
        loadCandidatesForStatus(candidateStatus);
      }
    };
    loadCandidates();
  }, [currentJobAdId, isAllJobSelected, JSON.stringify(candidateStatuses), searchValue]);

  return (
    <>
      <div
        style={{
          height: "100%",
        }}
      >
        <Kanban
          onDropped={handleOnDropped}
          items={candidateIdsByStatus}
          containers={containers}
          sortFunction={sortFunction}
          canDrop={(sourceId, targetId, itemId) => {
            return (
              targetId !== recommendedStatus?.id ||
              candidateIdsByStatus[recommendedStatus.id]?.includes(itemId as string)
            );
          }}
          renderContainer={(containerId) => <ContainerHeader containerId={containerId} />}
          renderItem={(value) => {
            const candidate = candidatesMap.get(value);
            if (!candidate) {
              return <></>;
            }
            return (
              <CandidateCardContent
                candidate={candidate}
                onClick={handleClickCandidate}
                showJobName={isAllJobSelected}
              />
            );
          }}
        />
      </div>
      {!!selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id + selectedCandidate.companyCandidateStatus.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
        />
      )}
      <FirstMessageModal
        open={showMessageModal}
        onCancel={handleOnCancelSendFirstMessage}
        onSend={handleSendFirstMessage}
        hasError={errorSendFirstMessage}
      />
      <RejectionModal
        open={showRejectionModal}
        isFromRecommendedStatus={rejectedCandidateIsFromRecommended}
        onCancel={handleOnCancelReject}
        onReject={handleOnReject}
      />
      <HiringModal open={showHiringModal} onCancel={handleOnCancelHire} onHire={handleOnHire} />
    </>
  );
};
