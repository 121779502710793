import { KeyCompanyDataForm, KeyDataType } from "@app/types/company-key-data.types";
import { v4 } from "uuid";

// use dummy data to render the fields that user haven't provided any value.
export const initialKeyCompanyData: KeyCompanyDataForm[] = Object.values(KeyDataType).map((i) => {
  return {
    id: `temp-${v4()}`,
    name: i,
    value: undefined,
    isTemp: true,
  };
});
