import { getTranslations } from "@app/api/translations.api";
import { LATEST_LOCAL_FETCH } from "@app/constants/translations.constants";
import i18n from "@app/translations/i18n";
import { WeblateProjects } from "@app/types/translations.types";
import moment from "moment";

export function useLoadTranslations() {
  const loadProject = async (project: WeblateProjects) => {
    try {
      const lang = i18n.language;
      const lastLocalFetch = moment.utc(LATEST_LOCAL_FETCH);
      const lastTranslationFetch =
        localStorage.getItem(`lastTranslationFetch-${project}-${lang}`) || undefined;
      let since = lastLocalFetch;

      // If we have more recent translations in local storage than in assets
      if (lastTranslationFetch && moment(lastTranslationFetch).isAfter(lastLocalFetch)) {
        since = moment(lastTranslationFetch);
        const savedTranslations = localStorage.getItem(`${project}-${lang}.json`);
        if (savedTranslations) {
          i18n.addResourceBundle(lang, "translation", JSON.parse(savedTranslations));
        }
      }

      const now = moment();
      const res = await getTranslations(project, lang, since.toDate());
      if (res.translations) {
        // We got more recent translations
        i18n.addResourceBundle(lang, "translation", res.translations);
        localStorage.setItem(`${project}-${lang}.json`, JSON.stringify(res.translations));
        localStorage.setItem(`lastTranslationFetch-${project}-${lang}`, now.toISOString());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadTranslations = () => {
    loadProject(WeblateProjects.ADMIN_PANEL);
    loadProject(WeblateProjects.SHARED);
  };

  return {
    loadTranslations,
  };
}
