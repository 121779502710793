import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState, store } from "@app/store/store";
import { Input, Modal, Typography, message } from "antd";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type SuperAdminPasswordModalProps = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
};

const SuperAdminPasswordModal = ({ open, setOpen, onCancel }: SuperAdminPasswordModalProps) => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const navigate = useNavigate();
  const loading = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.superAdminCheck
  );
  const [superAdminPassword, setSuperAdminPassword] = useState("");

  const handleSuperAdminModalOk = async () => {
    try {
      await dispatch.superAdmin.superAdminCheck(superAdminPassword);
      navigate(RoutePaths.SUPER_ADMIN_STUFF);
      setOpen(false);
      setSuperAdminPassword("");
    } catch (e) {
      if (e instanceof Error && e.message) {
        message.error({ type: "error", content: t("Invalid credentials") });
      }
    }
  };

  const handleSuperAdminModalCancel = () => {
    setOpen(false);
    setSuperAdminPassword("");
    onCancel?.();
  };

  return (
    <Modal
      title="Super admin"
      open={open}
      onOk={handleSuperAdminModalOk}
      confirmLoading={loading}
      onCancel={handleSuperAdminModalCancel}
      okButtonProps={{ size: "large", disabled: !superAdminPassword }}
      cancelButtonProps={{ size: "large" }}
    >
      <div>
        <Typography.Title level={5}>{t("Enter super admin password")}</Typography.Title>
        <Input.Password
          onChange={(e) => setSuperAdminPassword(e.target.value)}
          placeholder="input password"
        />
      </div>
    </Modal>
  );
};

export default SuperAdminPasswordModal;
