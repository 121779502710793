import { getUserSettings, putUserSettings } from "@app/api/user-settings.api";
import { UserSettingsDef } from "@app/types/user-settings.types";
import { createModel } from "@rematch/core";
import { RootModel } from "./models";

export const userSettings = createModel<RootModel>()({
  state: {
    userSettings: {
      id: "",
      lang: "",
    } as UserSettingsDef,
  },
  reducers: {
    updateUserSettings: (state, payload: UserSettingsDef) => {
      state.userSettings = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getUserSettings: async () => {
      try {
        const result = await getUserSettings();
        dispatch.userSettings.updateUserSettings(result);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    updateUserSettingsAction: async (payload: UserSettingsDef) => {
      const result = await putUserSettings(payload);
      dispatch.userSettings.updateUserSettings(result);
    },
  }),
});
