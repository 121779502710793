import { ALL_ROUTES_FLATTEN } from "@app/features/routes/constants/routes.constants";
import { RouteDef, RoutePaths, RoutePathsValue } from "@app/features/routes/types/routes.types";
import { getKeys } from "@app/helpers/util.helper";
import { useAccountSetupProgress } from "@app/hooks/useAccountSetupProgress";
import { AccountSetupProgress } from "@app/types/account-setup.types";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { StepProps, Steps } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddLinkToWebsiteModal } from "../AddLinkToWebsiteModal/AddLinkToWebsiteModal";
import styles from "./OnboardingSteps.module.scss";

const ROUTE_MAP: Record<keyof AccountSetupProgress["steps"], RoutePathsValue> = Object.freeze({
  companyInfo: RoutePaths.COMPANY_INFO,
  trades: RoutePaths.COMPANY_TRADES,
  keyData: RoutePaths.KEY_DATA,
  projects: RoutePaths.CONSTRUCTION_PROJECTS,
  machines: RoutePaths.EQUIPMENT_AND_MACHINERY,
  employeeBenefits: RoutePaths.EMPLOYEE_BENEFITS,
});

type CustomStepProps = StepProps & { key: string };

export const OnboardingSteps = () => {
  const { steps, approvalStatus, hasWebsiteLink } = useAccountSetupProgress();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showWebsiteLinkModal, setShowWebsiteLinkModal] = useState(false);
  const hasSubmittedForReview = approvalStatus !== ApprovalStatusEnum.NOT_STARTED;

  const getStepInfo = (
    item: keyof AccountSetupProgress["steps"]
  ): Pick<RouteDef, "key" | "label" | "description"> | undefined => {
    return ALL_ROUTES_FLATTEN.find((r) => r.key === ROUTE_MAP[item]);
  };

  const items: CustomStepProps[] = getKeys(steps)
    .map((step) => {
      const item = getStepInfo(step);
      if (!item) {
        return null;
      }
      const { label, description, key } = item;
      const isComplete = steps[step];
      return {
        key,
        title: typeof label === "string" ? t(label) : "",
        description: description ? t(description) : "",
        status: isComplete ? "finish" : "process",
        className: clsx({
          [styles.step]: isComplete,
        }),
      };
    })
    .filter(Boolean) as CustomStepProps[];

  items.push({
    key: "hasWebsiteLink",
    title: t("Add your profile to your website"),
    description: t("Add a link to your Crafthunt profile to your company website."),
    status: hasWebsiteLink ? "finish" : "process",
    className: clsx({
      [styles.step]: hasWebsiteLink,
    }),
  });

  items.push({
    key: "submit",
    title: t("Submit for review"),
    description: t(
      "We will review your information before approving your company to be shared with Crafthunt users."
    ),
    status: hasSubmittedForReview ? "finish" : "wait",
    className: clsx({
      [styles.step]: hasSubmittedForReview,
    }),
  });

  const handleOnClick = (currentNumber: number) => {
    const item = items[currentNumber];
    if (item.key === "hasWebsiteLink") {
      setShowWebsiteLinkModal(true);
    } else {
      navigate(item.key);
    }
  };

  return (
    <>
      <Steps
        direction="vertical"
        size="small"
        className={styles.steps}
        current={items.length - 1}
        onChange={handleOnClick}
        items={items}
      />
      <AddLinkToWebsiteModal
        close={() => {
          setShowWebsiteLinkModal(false);
        }}
        open={showWebsiteLinkModal}
      />
    </>
  );
};
