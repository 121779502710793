import { ENV } from "@app/constants/env";
import i18n from "@app/translations/i18n";
import {
  CreateJobAdDef,
  EJobAdStatus,
  IJobAdApiResponse,
  JobAdDef,
  UpdateJobAdDef,
} from "@app/types/job-ads.types";
import moment from "moment-timezone";
import { convertLangForBackend } from "./language.helper";

export const mapFormDataToApi = (data: CreateJobAdDef | UpdateJobAdDef) => {
  return {
    ...data,
    salaryMin: data.salaryMin === 0 ? null : data.salaryMin,
    salaryMax: data.salaryMax === 0 ? null : data.salaryMax,
    address: !data.useCompanyAddress && data.temp_address ? data.temp_address.address : null,
    coordinate:
      !data.useCompanyAddress && data.temp_address && data.temp_address.coordinates
        ? {
            lat: Number(data.temp_address.coordinates.lat),
            lng: Number(data.temp_address.coordinates.lng),
            geoJsonType: "Point",
          }
        : null,
    temp_address: undefined,
  } as CreateJobAdDef | UpdateJobAdDef;
};

export const mapResponseToFormData = (data: IJobAdApiResponse) => {
  return {
    ...data,
    projectTypesId: data.projectTypes.map((projectType) => projectType.id),
    jobStartDate: data.jobStartDate ? moment(data.jobStartDate) : null,
    jobEndDate: data.jobEndDate ? moment(data.jobEndDate) : null,
    temp_address: data.address
      ? {
          address: data.address,
          coordinates: data.coordinate,
        }
      : null,
    requiredLanguages: data.requiredLanguages?.map((item) => item.value) || null,
    requiredCountries: data.requiredCountries?.map((item) => item.value) || null,
  } as JobAdDef;
};

export const getJobPageUrl = ({
  fullSlug,
  status,
}: {
  fullSlug: JobAdDef["fullSlug"];
  status?: JobAdDef["status"];
}) => {
  return `${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/${fullSlug}${
    !status || status === EJobAdStatus.DRAFT ? "?mode=view-draft" : ""
  }`;
};
