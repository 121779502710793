import { sendChatMessage } from "@app/api/chat-messages.api";
import { triggerEventAcceptRecommendedCandidate } from "@app/components/pages/Candidates/helpers/candidates-analytics.helper";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { RootState } from "@app/store/store";
import {
  CandidateDef,
  CandidateRejectionFormDef,
  ECandidateRejectionReason,
  ECandidateStatus,
  UpdateCandidateDef,
} from "@app/types/candidate.types";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CandidateStatusIcon } from "../../../CandidateStatusIcon/CandidateStatusIcon";
import { FirstMessageModal } from "../../../FirstMessageModal/FirstMessageModal";
import { HiringModal } from "../../../HiringModal/HiringModal";
import { RejectionModal } from "../../../RejectionModal/RejectionModal";
import styles from "./StatusSelect.module.scss";

type StatusSelectProps = {
  candidate: CandidateDef;
  updateCandidate: (payload: UpdateCandidateDef) => Promise<void>;
};

export const StatusSelect = ({ candidate, updateCandidate }: StatusSelectProps) => {
  const { t } = useTranslation();
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectedCandidateIsFromRecommended, setRejectedCandidateIsFromRecommended] =
    useState(false);
  const [showHiringModal, setShowHiringModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorSendFirstMessage, setErrorSendFirstMessage] = useState(false);
  const [currentStatusId, setCurrentStatusId] = useState<string>(
    candidate.companyCandidateStatus.id
  );
  const [previousStatusId, setPreviousStatusId] = useState<string>(
    candidate.companyCandidateStatus.id
  );

  const getStatusById = (statusId: string) => {
    return candidateStatuses.find((status) => status.id == statusId);
  };

  const handleOnCancelHire = () => {
    setCurrentStatusId(previousStatusId);
    setShowHiringModal(false);
  };

  const handleOnHire = () => {
    saveCandidate(currentStatusId);
    setShowHiringModal(false);
  };

  const handleOnCancelReject = () => {
    setCurrentStatusId(previousStatusId);
    setShowRejectionModal(false);
  };

  const handleOnReject = (data: CandidateRejectionFormDef) => {
    saveCandidate(
      currentStatusId,
      data.rejectionMessage,
      data.rejectionReason,
      data.rejectionReasonDescription,
      !rejectedCandidateIsFromRecommended
    );
    setShowRejectionModal(false);
  };

  const handleOnCancelSendFirstMessage = () => {
    setCurrentStatusId(previousStatusId);
    setShowMessageModal(false);
  };

  const handleSendFirstMessage = async (message: string) => {
    try {
      await saveCandidate(currentStatusId);
      await sendChatMessage(message, candidate.id);
      triggerEventAcceptRecommendedCandidate(candidate.id);
      setShowMessageModal(false);
    } catch (error) {
      console.error(error);
      setErrorSendFirstMessage(true);
    }
  };

  const saveCandidate = async (
    newStatus: string,
    rejectionMessage?: string,
    rejectionReason?: ECandidateRejectionReason,
    rejectionReasonDescription?: string,
    handleRejection?: boolean
  ) => {
    await updateCandidate({
      companyCandidateStatusId: newStatus,
      rejectionMessage,
      rejectionReason,
      rejectionReasonDescription,
      handleRejection,
    });
  };

  const handleStatusChange = async (newStatus: string) => {
    const candidateStatus = getStatusById(newStatus);
    const currentStatus = getStatusById(currentStatusId);

    if (!candidateStatus || currentStatusId === newStatus) {
      return;
    }

    setPreviousStatusId(currentStatusId);
    setCurrentStatusId(newStatus);

    if (
      currentStatus?.status === ECandidateStatus.RECOMMENDED &&
      candidateStatus.status !== ECandidateStatus.REJECTED
    ) {
      setShowMessageModal(true);
    } else if (candidateStatus.status === ECandidateStatus.REJECTED) {
      setRejectedCandidateIsFromRecommended(currentStatus?.status === ECandidateStatus.RECOMMENDED);
      setShowRejectionModal(true);
    } else if (candidateStatus.status === ECandidateStatus.HIRED) {
      setShowHiringModal(true);
    } else {
      saveCandidate(newStatus);
    }
  };

  useEffect(() => {
    setCurrentStatusId(candidate.companyCandidateStatus.id);
  }, [candidate.companyCandidateStatus.id]);

  return (
    <>
      <label htmlFor="candidate-selected-status">{t("company-candidate-status-title")}</label>
      <SelectAutocomplete
        id="candidate-selected-status"
        value={currentStatusId}
        onChange={handleStatusChange}
        defaultActiveFirstOption
        sortAlphabetically={false}
      >
        {candidateStatuses
          .filter(
            (status) =>
              getStatusById(currentStatusId)?.status === ECandidateStatus.RECOMMENDED ||
              status.status !== ECandidateStatus.RECOMMENDED
          )
          .map((candidateStatus) => {
            const label =
              candidateStatus.status === ECandidateStatus.CUSTOM
                ? candidateStatus.name
                : t(candidateStatus.name);
            return (
              <Select.Option key={candidateStatus.id} value={candidateStatus.id} label={label}>
                <div className={styles.option}>
                  <CandidateStatusIcon candidateStatusName={candidateStatus.name} />
                  <span>{label}</span>
                </div>
              </Select.Option>
            );
          })}
      </SelectAutocomplete>
      <FirstMessageModal
        open={showMessageModal}
        onCancel={handleOnCancelSendFirstMessage}
        onSend={handleSendFirstMessage}
        hasError={errorSendFirstMessage}
      />
      <RejectionModal
        open={showRejectionModal}
        isFromRecommendedStatus={rejectedCandidateIsFromRecommended}
        onCancel={handleOnCancelReject}
        onReject={handleOnReject}
      />
      <HiringModal open={showHiringModal} onCancel={handleOnCancelHire} onHire={handleOnHire} />
    </>
  );
};
