import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import { FormInstance, FormProps } from "antd/lib/form/Form";
import { ReactNode } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;

type FormModalProps<T> = {
  open: boolean;
  title: string;
  onCancel: () => void;
  children: ReactNode;
  form: FormInstance<T>;
} & Omit<FormProps, "form">;

export const FormModal = <T,>({
  open,
  title,
  onCancel,
  children,
  form,
  initialValues,
  ...rest
}: FormModalProps<T>) => {
  const { t } = useTranslation();
  const handleOk = () => {
    form.submit();
  };
  const handleReset = () => {
    form.resetFields();
  };
  const handleOnCancel = () => {
    const allValues = form.getFieldsValue(true);
    const formIsEmpty = !initialValues && !Object.values(allValues).length;
    const formNotChanged = isEqual(initialValues, allValues);
    if (formIsEmpty || formNotChanged) {
      onCancel();
    } else {
      confirm({
        title: t("You have unsaved changes, are you sure you want to leave?"),
        icon: <ExclamationCircleOutlined />,
        closable: true,
        maskClosable: true,
        cancelText: t("Cancel"),
        onOk: onCancel,
        onCancel: () => null,
      });
    }
  };

  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleOnCancel}
      destroyOnClose
      forceRender
      afterClose={handleReset}
    >
      <Form
        layout="vertical"
        labelAlign="right"
        form={form}
        initialValues={initialValues}
        data-hs-do-not-collect="true"
        {...rest}
      >
        {children}
      </Form>
    </Modal>
  );
};
