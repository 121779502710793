import { StopOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Colors } from "@app/constants/colors.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { Button } from "@app/components/ui/Button/Button";
import { Link } from "react-router-dom";

export const NothingToSeeHere = () => {
  const { t } = useTranslation();

  return (
    <Row
      align="middle"
      justify="center"
      style={{ height: "100%" }}
      gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
    >
      <Col>
        <StopOutlined
          style={{
            fontSize: "60pt",
            color: Colors.primary,
          }}
        />
      </Col>
      <Col>{t("Oh no! That page doesn't exist.")}</Col>
      <Col>
        <Link key="new" to={RoutePaths.DASHBOARD}>
          <Button style={{ color: "black" }} type="primary">
            {t("Go home")}
          </Button>
        </Link>
      </Col>
    </Row>
  );
};
