import defaultProfilePicture from "@app/assets/profileImagePlaceholder.png";
import { Colors } from "@app/constants/colors.constants";
import { ENV } from "@app/constants/env";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { getLangName } from "@app/helpers/language.helper";
import { CandidateDef } from "@app/types/candidate.types";
import { Avatar, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { MdMail, MdMessage, MdPerson, MdPhone, MdTranslate, MdVerified } from "react-icons/md";
import { CandidateName } from "../../../CandidateName/CandidateName";
import styles from "./HeaderInfo.module.scss";
import { HeaderButton } from "./components/HeaderButton/HeaderButton";
import { HeaderContact } from "./components/HeaderContact/HeaderContact";

type HeaderInfoProps = {
  candidate: CandidateDef;
};

export const HeaderInfo = ({ candidate }: HeaderInfoProps) => {
  const { t, i18n } = useTranslation();

  const isUserDeleted = !!candidate.workerProfile.deletedAt;

  return (
    <div className={styles.container}>
      <Space direction="vertical" size="middle" style={{ textAlign: "center" }}>
        <div>
          <div>
            <Avatar
              size={78}
              src={(!isUserDeleted && candidate.workerProfile.picture) || defaultProfilePicture}
            />
          </div>
          <CandidateName candidate={candidate} />
          <div>
            {candidate.workerProfile.isVerified && (
              <Space align="center">
                <MdVerified style={{ color: Colors.primary }} />
                <Typography.Text key="cv" style={{ fontSize: 14 }}>
                  {t("verified-by-crafthunt")}
                </Typography.Text>
              </Space>
            )}
          </div>
        </div>

        <Space size="small">
          {!isUserDeleted && (
            <HeaderButton
              href={`${ENV.WEB_URL}/craftsmen/${
                candidate.workerProfile.slug || candidate.workerProfile.anonymousSlug
              }`}
              externalLink
              target="_blank"
              label={t("chat-profile")}
              icon={MdPerson}
            />
          )}
          <HeaderButton
            href={RoutePaths.CHAT + `?candidateId=${candidate.id}`}
            label={t("chat")}
            icon={MdMessage}
          />
        </Space>
      </Space>

      <Space direction="vertical" size="middle">
        {!!candidate.workerProfile.phone && (
          <HeaderContact
            icon={MdPhone}
            href={`tel:${candidate.workerProfile.phone}`}
            label={candidate.workerProfile.phone}
          />
        )}
        {!!candidate.workerProfile.email && (
          <HeaderContact
            icon={MdMail}
            href={`mailto:${candidate.workerProfile.email}`}
            label={candidate.workerProfile.email}
          />
        )}
        {!!candidate.workerProfile.stats?.languages?.length && (
          <HeaderContact
            icon={MdTranslate}
            label={candidate.workerProfile.stats.languages
              .map((langCode) => getLangName(i18n.language, langCode))
              .join(", ")}
          />
        )}
        {candidate.candidateApprovals.map((approval) => (
          <div key={approval.id}>
            <label>{t("approval-note-title")}</label>
            <TextArea
              autoSize
              disabled
              className={styles.interviewerNote}
              defaultValue={approval.description}
            />
          </div>
        ))}
      </Space>
    </div>
  );
};
