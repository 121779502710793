import { UserOutlined } from "@ant-design/icons";
import { RootState } from "@app/store/store";
import { Divider, List, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CTACards } from "./CTACards/CTACards";

const { Title } = Typography;

export const Dashboard = () => {
  const companyName = useSelector((state: RootState) => state.companyInfo.companyInfo.name);
  const fullName = useSelector((state: RootState) => state.auth.fullName);
  const email = useSelector((state: RootState) => state.auth.email);
  const { t } = useTranslation();

  return (
    <div
      style={{
        maxWidth: "1000px",
        marginTop: "3rem",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Title id="welcome-banner">{t("Welcome, {{0}}", { 0: companyName })}</Title>
      <List.Item>
        <List.Item.Meta
          avatar={<UserOutlined style={{ fontSize: "20pt" }} />}
          title={fullName}
          description={<a href={`mailto:${email}`}>{email}</a>}
        />
      </List.Item>
      <Divider />
      <CTACards />
    </div>
  );
};
