import { PlusOutlined } from "@ant-design/icons";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { RemovableCardForm } from "@app/components/ui/RemovableCardForm/RemovableCardForm";
import {
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
} from "@app/components/ui/RemovableCardForm/RemovableCardForm.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { useLoadingPopup } from "@app/hooks/useLoadingPopup";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import { CompanyTradeDef, TempCompanyTradeDef } from "@app/types/trades.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Col, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

export const CompanyTrades = () => {
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const savedCompanyTrades = useSelector((state: RootState) => state.trades.companyTrades);
  const [innerTrades, setInnerTrades] = useState<CompanyTradeDef[]>([]);
  const [unsavedTrades, setUnsavedTrades] = useState<TempCompanyTradeDef[]>([]);
  const loading = useLoadingPopup(ModelNames.TRADES);
  const lastCard = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch } = store;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!loading) {
      setInnerTrades(savedCompanyTrades);
    }
  }, [savedCompanyTrades, loading]);

  const options: RemovableCardFormInputOptions<CompanyTradeDef | TempCompanyTradeDef>[] = [
    {
      inputType: RemovableCardFormInputTypes.textField,
      required: true,
      description: t("Trade"),
      value: "description",
      placeholder: "Enter name of trade...",
    },
    {
      inputType: RemovableCardFormInputTypes.select,
      required: true,
      description: t("Trade Category"),
      value: "tagId",
      placeholder: "Select",
      choices: availableTradesTags.map((i) => ({
        value: i.id,
        text: t(i.name),
      })),
    },
  ];

  function addTrade() {
    const trade: TempCompanyTradeDef = {
      id: `temp-${v4()}`,
      description: "",
      tagId: "",
    };
    setUnsavedTrades([...unsavedTrades, trade]);
    setTimeout(() => {
      (lastCard.current as unknown as HTMLElement)?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }

  async function removeTrade(trade: CompanyTradeDef | TempCompanyTradeDef, index: number) {
    if ("companyId" in trade) {
      await dispatch.trades.removeTradeAction(trade.id);
    } else {
      if (index > -1) {
        const splicedOut = [...unsavedTrades];
        splicedOut.splice(index, 1);
        setUnsavedTrades(splicedOut);
      }
    }
  }

  async function saveTrade(trade: CompanyTradeDef | TempCompanyTradeDef, index: number) {
    setIsSaving(true);
    if ("companyId" in trade) {
      await dispatch.trades.updateTradeAction({
        ...trade,
        lang: convertLangForBackend(i18n.language),
      });
    } else {
      if (index > -1) {
        await dispatch.trades.addTradeAction({
          ...trade,
          lang: convertLangForBackend(i18n.language),
        });
        const newArr = [...unsavedTrades];
        newArr.splice(index, 1);
        setUnsavedTrades(newArr);
      }
    }
    setIsSaving(false);
  }

  return (
    <ContentLayout
      title={t("What Trades do you have in your company?")}
      description={t(
        "Let people that view your profile know which trades you have in your company. This will let craftsmen easily see if you could be the right place for them"
      )}
      nextRoute={RoutePaths.KEY_DATA}
    >
      <Col xs={24}>
        <Typography.Title level={5}>
          {t("Your trades")}
          <Button onClick={() => addTrade()} style={{ marginLeft: "20px" }} type="primary">
            <PlusOutlined />
            {t("Add trades")}
          </Button>
        </Typography.Title>
      </Col>
      <Col xs={24} style={{ position: "relative" }}>
        {isSaving && <LoadingSpinner />}
        <Space wrap>
          {innerTrades.map((trade, index) => (
            <RemovableCardForm
              key={trade.id}
              options={options}
              width="300px"
              onRemove={(i) => removeTrade(i, index)}
              onSave={(i) => saveTrade(i, index)}
              initialValues={trade}
            />
          ))}
          {unsavedTrades.map((trade, index, arr) => (
            <div key={trade.id} ref={index === arr.length - 1 ? lastCard : null}>
              <RemovableCardForm
                key={trade.id}
                options={options}
                width="300px"
                onRemove={(i) => removeTrade(i, index)}
                onSave={(i) => saveTrade(i, index)}
                initialValues={trade}
              />
            </div>
          ))}
        </Space>
      </Col>
    </ContentLayout>
  );
};
