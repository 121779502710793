import { EngagementSADef } from "../types/super-admin.engagement.types";
import { deleteCached, getCached, putCached } from "./super-admin.api";

export const getEngagementSA = (companyId: string, workerProfileId: string) => {
  return getCached<EngagementSADef>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};

export const recommendCandidateSA = (companyId: string, workerProfileId: string) => {
  return putCached<EngagementSADef>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};

export const deleteRecommendationSA = (companyId: string, workerProfileId: string) => {
  return deleteCached<void>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};
