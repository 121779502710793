import { Col, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Colors } from "@app/constants/colors.constants";
import { Logo } from "@app/components/ui/Logo/Logo";

export const FullPageSpinner = () => {
  return (
    <Content
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: Colors.darkBackground,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "20%",
          left: "47%",
          transform: "translateX(-50%)",
        }}
      >
        <Row>
          <Col xs={6}>
            <Spin
              size="large"
              style={{
                paddingRight: "20px",
              }}
            />
          </Col>
          <Col xs={18}>
            <div
              style={{
                width: "200px",
              }}
            >
              <Logo />
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  );
};
