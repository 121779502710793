import { AxiosError } from "axios";

export type ApiFormErrorDef = AxiosError<{
  className: string;
  code: number;
  data:
    | { context: string }
    | {
        constraints: Record<string, string>;
        property: string;
        value: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: any[];
      }[];
  errors: { message: string[]; property: string }[];
  message: string;
  name: string;
}>;

export const getErrorMessages = (error: ApiFormErrorDef) => {
  const data = error.response?.data;
  console.log("data", data);
  if (data?.data && Array.isArray(data.data)) {
    return data.data
      .map((item) => Object.values(item.constraints).map((value) => `"${item.property}": ${value}`))
      .join("\n");
  }
  if (data?.errors && Array.isArray(data.errors)) {
    return data.errors
      .map((item) => item.message?.map((innerItem) => `- ${innerItem}`).join("\n"))
      .join("\n");
  }
  if (data?.message) {
    return data?.message;
  }
  console.error(error);
  return typeof error === "string" ? error : "";
};
