import { ALL_LANGUAGES } from "@app/constants/languages.constants";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import clsx from "clsx";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import type { CustomLabels } from "react-flags-select/build/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import styles from "./LanguagePicker.module.scss";

type LanguagePickerProps = {
  isFixed?: boolean;
};

export const LanguagePicker = ({ isFixed }: LanguagePickerProps) => {
  const { i18n } = useTranslation();
  const { dispatch } = store;
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(
    ALL_LANGUAGES.find((lang) => lang.value === i18n.language)?.flagIcon || "GB"
  );
  const token = useSelector((state: RootState) => state.auth.token);
  const existingCompanyInfo =
    useSelector((state: RootState) => state.companyInfo.companyInfo) || null;
  const existingUserSettings = useSelector((state: RootState) => state.userSettings) || null;
  const customLabels = ALL_LANGUAGES.reduce((result, item) => {
    result[item.flagIcon] = item.name;
    return result;
  }, {} as CustomLabels);
  const countries = ALL_LANGUAGES.map((lang) => lang.flagIcon);

  const onSelectLang = async (value: string) => {
    const lang = ALL_LANGUAGES.find((lang) => lang.flagIcon === value);
    if (!lang) {
      return;
    }
    if (token && existingCompanyInfo.lang !== lang.value) {
      setIsLoading(true);
      const langForBackend = convertLangForBackend(lang.value);
      await dispatch.companyInfo.updateCompanyInfoAction({
        info: {
          ...existingCompanyInfo,
          lang: langForBackend,
        },
      });
      await dispatch.userSettings.updateUserSettingsAction({
        ...existingUserSettings.userSettings,
        lang: langForBackend,
      });
    }
    setSelected(lang.flagIcon);
    i18n.changeLanguage(lang.value);
    // Currently reload fixes google map error after language change
    window.location.reload();
  };

  return (
    <div className={clsx(styles.container, { [styles.fixed]: isFixed })}>
      <ReactFlagsSelect
        selected={selected}
        countries={countries}
        customLabels={customLabels}
        onSelect={onSelectLang}
        placeholder={` `} // needs empty string to hide default placeholder
        fullWidth={false}
        alignOptionsToRight
        selectButtonClassName={styles.button}
      />
      {isLoading && <LoadingSpinner />}
    </div>
  );
};
