import { UploadFile } from "antd";
import { FileDef } from "../types/super-admin.user.types";

export const mapFilesToApi = (files: UploadFile[]): FileDef[] => {
  return files.map(
    (file) =>
      ({
        id: file.uid,
        url: file.url,
        filename: file.name,
        type: file.type,
      } as FileDef)
  );
};
export const mapApiFilesToForm = (files: FileDef[]): UploadFile[] => {
  return files.map((file) => ({
    uid: file.id,
    url: file.url,
    name: file.filename || file.url,
    type: file.type,
    lastModifiedDate: new Date(file.createdAt),
  }));
};
