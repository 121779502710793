import * as api from "@app/api/integration-jobs.api";
import { IntegrationJobDef } from "@app/types/integration-jobs.types";
import { createModel } from "@rematch/core";
import { RootModel } from "./models";

export const integrationJobs = createModel<RootModel>()({
  state: {
    integrationJobs: [] as IntegrationJobDef[],
  },
  reducers: {
    addJobAds: (state, payload: IntegrationJobDef[]) => {
      state.integrationJobs = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getIntegrationJobsAction: async (searchQuery?: string) => {
      const result = await api.getIntegrationJobs(searchQuery);
      dispatch.integrationJobs.addJobAds(result);
    },
  }),
});
