import {
  getAllCertificates,
  getAllJobTitles,
  getAllProjectTypes,
  getAllTrades,
} from "@app/api/tags.api";
import {
  CertificateTagDef,
  JobTitleTagDef,
  ProjectTypeTagDef,
  TradeTagDef,
} from "@app/types/tags.types";
import { createModel } from "@rematch/core";
import { RootModel } from "./models";

export const tags = createModel<RootModel>()({
  state: {
    jobTitles: [] as JobTitleTagDef[],
    trades: [] as TradeTagDef[],
    projectTypes: [] as ProjectTypeTagDef[],
    certificates: [] as CertificateTagDef[],
  },
  reducers: {
    addAllJobTitles: (state, payload: JobTitleTagDef[]) => {
      state.jobTitles = payload;
      return state;
    },
    addAllTrades: (state, payload: TradeTagDef[]) => {
      state.trades = payload;
      return state;
    },
    addAllProjectTypes: (state, payload: ProjectTypeTagDef[]) => {
      state.projectTypes = payload;
      return state;
    },
    addAllCertificates: (state, payload: CertificateTagDef[]) => {
      state.certificates = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getAllJobTitles: async () => {
      try {
        const result = await getAllJobTitles();
        dispatch.tags.addAllJobTitles(result.data);
      } catch (e) {
        console.error(e);
      }
    },
    getAllTrades: async () => {
      try {
        const result = await getAllTrades();
        dispatch.tags.addAllTrades(result.data);
      } catch (e) {
        console.error(e);
      }
    },
    getAllProjectTypes: async () => {
      try {
        const result = await getAllProjectTypes();
        dispatch.tags.addAllProjectTypes(result.data);
      } catch (e) {
        console.error(e);
      }
    },
    getAllCertificates: async () => {
      try {
        const result = await getAllCertificates();
        dispatch.tags.addAllCertificates(result.data);
      } catch (e) {
        console.error(e);
      }
    },
  }),
});
