import { Carousel, Typography } from "antd";
import styles from "./AuthCarousel.module.scss";
import { QuoteIcon } from "@app/components/ui/QuoteIcon/QuoteIcon";
import { Colors } from "@app/constants/colors.constants";
import { useTranslation } from "react-i18next";

const { Title, Text, Link } = Typography;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const SlickButtonFix = ({ currentSlide, slideCount, ...props }: any) => <span {...props} />;

const TESTIMONIALS = [
  {
    image: "https://a.storyblok.com/f/123131/583x583/078168ccfe/philipprochollbubble.jpg?cv=NaN",
    title: "testimonial-quote-rocholl",
    author: "Philipp Rocholl",
    authorRole: "testimonial-role-rocholl",
    authorCompany: "Rocholl Garten-, Landschafts- und Tiefbau GmbH",
    authorLink:
      "https://crafthunt.app/companies/rocholl-garten-landschafts-und-tiefbau-gmb-h-wEUjuoz6",
    companyLogo: "https://a.storyblok.com/f/123131/x/ecd05761cf/rocholl_logo_header.svg",
    companyLogoHeight: 50,
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/prod-crafthunt/o/admin-panel%2FFalk.jpeg?alt=media&token=50a30344-6179-4b66-aa95-6aa8a703e114",
    title: "testimonial-quote-catterfeld",
    author: "Falk Hartmann",
    authorRole: "testimonial-role-catterfeld",
    authorCompany: "Catterfeld Welker GmbH",
    authorLink: "https://crafthunt.app/companies/catterfeld-welker-gmbh-arz941sl",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/prod-crafthunt/o/catterfeld_welker_logo_white.webp?alt=media&token=8be1810a-d82d-4eaa-a182-d45839193d23",
    companyLogoHeight: 85,
  },
];

const AUTOPLAY_SPEED_SECONDS = 10;

export const AuthCarousel = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.sticky}>
      <Carousel
        className={styles.carousel}
        arrows
        prevArrow={<SlickButtonFix />}
        nextArrow={<SlickButtonFix />}
        adaptiveHeight
        autoplay
        autoplaySpeed={AUTOPLAY_SPEED_SECONDS * 1000}
        pauseOnHover
      >
        {TESTIMONIALS.map((item) => (
          <div key={item.title + item.image} className={styles.container}>
            <div className={styles.innerContainer}>
              <img src={item.image} className={styles.image} />
              <div className={styles.content}>
                <QuoteIcon className={styles.quoteIcon} style={{ fill: Colors.primary }} />
                <Title level={3} className={styles.title}>
                  {t(item.title)}
                </Title>
                <div>
                  <Text>{item.author}</Text>
                </div>
                <Link href={item.authorLink} target="_blank" rel="noreferrer" underline>
                  {t(item.authorRole, { 0: item.authorCompany })}
                </Link>
                <img
                  src={item.companyLogo}
                  className={styles.companyLogo}
                  height={item.companyLogoHeight}
                />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
