import {
  ApiMachineResponse,
  MachineDef,
  PostMachineDef,
  UpdateMachineDef,
} from "@app/types/machines.types";
import moment from "moment-timezone";
import { api } from "./api";

export default {
  async getMachines(): Promise<MachineDef[]> {
    const response = await api.get<ApiMachineResponse[]>(`/crafthunts/machines`);
    return response.data.map((i) => {
      return {
        ...i,
        medias: i.medias?.map((m) => m.url) || [],
        yearOfManufacture: moment(i.yearOfManufacture).year(),
      };
    });
  },
  async postMachine(payload: PostMachineDef): Promise<MachineDef> {
    const response = await api.post<ApiMachineResponse>(`/crafthunts/machines`, {
      ...payload,
      yearOfManufacture: payload.yearOfManufacture
        ? moment(`${payload.yearOfManufacture}-06-01`, `YYYY-MM-DD`).format()
        : null,
    });
    return {
      ...response.data,
      medias: response.data.medias?.map((m) => m.url) || [],
      yearOfManufacture: response.data.yearOfManufacture
        ? moment(response.data.yearOfManufacture).year()
        : undefined,
    };
  },
  async putMachine(payload: UpdateMachineDef): Promise<MachineDef> {
    const response = await api.put<ApiMachineResponse>(`/crafthunts/machines/${payload.id}`, {
      ...payload,
      yearOfManufacture: payload.yearOfManufacture
        ? moment(`${payload.yearOfManufacture}-06-01`, `YYYY-MM-DD`).format()
        : null,
    });
    return {
      ...response.data,
      medias: response.data.medias?.map((m) => m.url) || [],
      yearOfManufacture: response.data.yearOfManufacture
        ? moment(response.data.yearOfManufacture).year()
        : undefined,
    };
  },
  removeMachine(id: string) {
    return api.delete<void>(`/crafthunts/machines/${id}`);
  },
};
