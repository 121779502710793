import { CandidateDef } from "@app/types/candidate.types";
import { useTranslation } from "react-i18next";

export const useGetCandidateName = () => {
  const { t } = useTranslation();

  const getAnonymousName = (candidate: CandidateDef) => {
    const jobTitleTranslation = t(
      candidate.workerProfile.jobTitle?.name || "chat-name-anonymous-user"
    );
    if (candidate.workerProfile.address?.city) {
      return t("chat-name-anonymous-job-city", {
        0: jobTitleTranslation,
        1: candidate.workerProfile.address?.city,
      });
    }
    return jobTitleTranslation;
  };

  const getCandidateName = (candidate: CandidateDef) => {
    if (!candidate.revealIdentity) {
      return getAnonymousName(candidate);
    }
    return [candidate.workerProfile.firstName, candidate.workerProfile.lastName]
      .filter(Boolean)
      .join(" ");
  };

  return { getCandidateName };
};
