import { ENV } from "@app/constants/env";
import { useCompaniesSA } from "@app/features/super-admin/api/super-admin.company.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CompanySADef } from "@app/features/super-admin/types/super-admin.company.types";
import { EngagementSAColumnsDataDef } from "@app/features/super-admin/types/super-admin.engagement.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { Input, Modal, Space, Table, TableColumnsType, TablePaginationConfig } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EngagementStatus } from "./components/EngagementStatus";
const { Search } = Input;

type EngagementModalProps = {
  open: boolean;
  selectedUserId?: string;
  onClose: () => void;
};

export const EngagementModal = ({ open, selectedUserId, onClose }: EngagementModalProps) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");

  const companiesQuery = useCompaniesSA({
    keyword,
    isPaying: true,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
  });

  const columns: TableColumnsType<EngagementSAColumnsDataDef> = [
    { title: t("Company name"), dataIndex: "companyName", key: "companyName", width: 150 },
    { title: t("Status"), dataIndex: "status", key: "status", fixed: "right", width: 100 },
  ];

  const generatedData = (data: CompanySADef[]): EngagementSAColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      companyName: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${item.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}
        </a>
      ),
      status: <EngagementStatus companyId={item.id} userId={selectedUserId} />,
    }));
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal
      title={t("engagement-modal-title")}
      open={open}
      onOk={handleOnClose}
      onCancel={handleOnClose}
      width="90%"
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for company id, company name or contact email")}
          enterButton={t("Search")}
          size="large"
          onSearch={(value) => {
            setPage(0);
            setKeyword(value);
          }}
          allowClear
        />
        <Table
          style={{ width: "100%" }}
          loading={companiesQuery.isLoading}
          columns={columns}
          scroll={{ x: 1270 }}
          dataSource={generatedData(companiesQuery.data?.data ?? [])}
          pagination={{
            current: page + 1,
            total: companiesQuery.data?.count,
            showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
          }}
          onChange={handlePageChange}
        />
      </Space>
    </Modal>
  );
};
