import benefitsApi from "@app/api/company-benefits.api";
import keyDataApi from "@app/api/key-data.api";
import {
  Benefit,
  PostCompanyBenefit,
  SavedCompanyBenefit,
  UpdateCompanyBenefit,
} from "@app/types/company-data.types";
import {
  KeyCompanyDataForm,
  PostKeyCompanyData,
  UpdateKeyCompanyData,
} from "@app/types/company-key-data.types";
import { createModel } from "@rematch/core";
import { RootModel } from "./models";

const HAS_WEBSITE_LINK_KEY = "has-website-link";

export const companyData = createModel<RootModel>()({
  state: {
    companyId: "",
    benefits: [] as Benefit[],
    companyBenefits: [] as SavedCompanyBenefit[],
    keyData: [] as KeyCompanyDataForm[],
    hasWebsiteLink: !!localStorage.getItem(HAS_WEBSITE_LINK_KEY),
  },
  reducers: {
    // COMPANY
    setCompanyId: (state, payload: string) => {
      state.companyId = payload;
      return state;
    },
    // BENEFITS
    addAllBenefits: (state, payload: Benefit[]) => {
      state.benefits = payload;
      return state;
    },
    addAllCompanyBenefits: (state, payload: SavedCompanyBenefit[]) => {
      state.companyBenefits = payload;
      return state;
    },
    addCompanyBenefit: (state, payload: SavedCompanyBenefit) => {
      state.companyBenefits.push(payload);
      return state;
    },
    updateCompanyBenefit: (
      state,
      payload: { companyBenefit: SavedCompanyBenefit; index: number }
    ) => {
      state.companyBenefits.splice(payload.index, 1, payload.companyBenefit);
      return state;
    },
    // KEY DATA
    addAllKeyData: (state, payload: KeyCompanyDataForm[]) => {
      state.keyData = payload;
      return state;
    },
    addKeyData: (state, payload: KeyCompanyDataForm) => {
      state.keyData.push(payload);
      return state;
    },
    updateKeyData: (state, payload: { keyData: KeyCompanyDataForm; index: number }) => {
      state.keyData.splice(payload.index, 1, payload.keyData);
      return state;
    },
    removeKeyData: (state, index: number) => {
      state.keyData.splice(index, 1);
      return state;
    },
    updateHasWebsiteLink: (state, payload: boolean) => {
      if (payload) {
        localStorage.setItem(HAS_WEBSITE_LINK_KEY, "true");
      } else {
        localStorage.removeItem(HAS_WEBSITE_LINK_KEY);
      }
      state.hasWebsiteLink = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    // BENEFITS
    getBenefits: async () => {
      try {
        const res = await benefitsApi.getBenefits();
        dispatch.companyData.addAllBenefits(res);
      } catch (e) {
        console.error(e);
      }
    },
    getCompanyBenefits: async () => {
      try {
        const res = await benefitsApi.getCompanyBenefits();
        dispatch.companyData.addAllCompanyBenefits(res);
      } catch (e) {
        console.error(e);
      }
    },
    addCompanyBenefitAction: async (payload: PostCompanyBenefit) => {
      const res = await benefitsApi.addCompanyBenefit(payload);
      dispatch.companyData.addCompanyBenefit(res);
    },
    updateCompanyBenefitAction: async (payload: UpdateCompanyBenefit, rootState) => {
      const companyBenefitIndex = rootState.companyData.companyBenefits.findIndex(
        (i) => i.id === payload.id
      );
      if (companyBenefitIndex > -1) {
        const response = await benefitsApi.updateCompanyBenefit(payload);
        dispatch.companyData.updateCompanyBenefit({
          companyBenefit: response,
          index: companyBenefitIndex,
        });
      }
    },
    // KEY DATA
    getKeyData: async () => {
      const result = await keyDataApi.getKeyData();
      dispatch.companyData.addAllKeyData(result);
    },
    addKeyDataAction: async (payload: PostKeyCompanyData) => {
      const result = await keyDataApi.postKeyData(payload);
      dispatch.companyData.addKeyData(result);
    },
    updateKeyDataAction: async (payload: UpdateKeyCompanyData, rootState) => {
      const result = await keyDataApi.putKeyData(payload);
      const keyDataIndex = rootState.companyData.keyData.findIndex((i) => i.id === payload.id);
      if (keyDataIndex > -1) {
        dispatch.companyData.updateKeyData({ keyData: result, index: keyDataIndex });
      }
    },
    removeKeyDataAction: async (id: string, rootState) => {
      await keyDataApi.deleteKeyData(id);
      const keyDataIndex = rootState.companyData.keyData.findIndex((i) => i.id === id);
      if (keyDataIndex > -1) {
        dispatch.companyData.removeKeyData(keyDataIndex);
      }
    },
  }),
});
