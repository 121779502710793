import { generateCompanyBio } from "@app/api/company-info.api";
import { AddressPicker } from "@app/components/ui/AddressPicker/AddressPicker";
import { CropAspectEnum, Uploader } from "@app/components/ui/Uploader/Uploader";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import { AddressObjDef } from "@app/types/address.types";
import {
  CompanyBioInputType,
  CompanyInfoType,
  InfoFieldsStatusType,
} from "@app/types/company-info.types";
import { StateStatusDef } from "@app/types/state-machine.types";
import { Button, Card, Col, Input, Spin, Tooltip, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertLangForBackend } from "../../helpers/language.helper";
import { useLoadingPopup } from "../../hooks/useLoadingPopup";
import { useUnsavedPrompt } from "../../hooks/useUnsavedPrompt";
import { ContentLayout } from "../layouts/ContentLayout/ContentLayout";

export const CompanyInfo = () => {
  const { t, i18n } = useTranslation();
  useLoadingPopup(ModelNames.COMPANY_INFO);
  const navigate = useNavigate();
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const [innerCompanyInfo, setInnerCompanyInfo] = useState<CompanyInfoType>(existingCompanyInfo);
  const [infoFieldsStatus, setInfoFieldsStatus] = useState<InfoFieldsStatusType>({
    name: "",
    description: "",
    street: "",
    postalCode: "",
  });
  const [isInfoValid, setIsInfoValid] = useState(false);
  const [isBioGenerating, setIsBioGenerating] = useState(false);
  const [hasValueChanges, setHasValueChanges] = useState(false);
  const { dispatch } = store;
  useUnsavedPrompt(t("You have unsaved changes, are you sure you want to leave?"), hasValueChanges);

  useEffect(() => {
    const valid = !!(
      innerCompanyInfo.address?.country &&
      innerCompanyInfo.address?.city &&
      innerCompanyInfo.address?.street &&
      innerCompanyInfo.address?.postalCode &&
      !isNaN(innerCompanyInfo.coordinates?.lat) &&
      !isNaN(innerCompanyInfo.coordinates?.lng) &&
      innerCompanyInfo.description?.trim() &&
      innerCompanyInfo.name?.trim()
    );
    setIsInfoValid(valid);
  }, [innerCompanyInfo]);

  const changeImages = (newImages: string[]) => {
    const newCompanyInfo = { ...innerCompanyInfo };
    newCompanyInfo.logo = newImages[0] || null;
    setInnerCompanyInfo(newCompanyInfo);
    setHasValueChanges(true);
  };

  const changeCoverImage = (newImages: string[]) => {
    const newCompanyInfo = { ...innerCompanyInfo };
    newCompanyInfo.coverImage = newImages[0] || null;
    setInnerCompanyInfo(newCompanyInfo);
    setHasValueChanges(true);
  };

  const changeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCompanyInfo = { ...innerCompanyInfo };
    newCompanyInfo.name = e.target.value;
    setInnerCompanyInfo(newCompanyInfo);
    setHasValueChanges(true);
  };

  const changeCompanyDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newCompanyInfo = { ...innerCompanyInfo };
    newCompanyInfo.description = e.target.value;
    setInnerCompanyInfo(newCompanyInfo);
    setHasValueChanges(true);
  };

  const changeCompanyAddress = (i: AddressObjDef | null) => {
    if (i?.address && i.coordinates) {
      setInnerCompanyInfo({
        ...innerCompanyInfo,
        address: i.address,
        coordinates: i.coordinates,
      });
      setHasValueChanges(true);
    }
  };

  const handleCompanyAddressStatus = (status: StateStatusDef) => {
    if (status === "error") {
      setInfoFieldsStatus((prevState) => ({
        ...prevState,
        street: "error",
        postalCode: "error",
      }));
      setIsInfoValid(false);
    }
  };

  const onSaveCompanyInfo = async () => {
    setInfoFieldsStatus({
      name: innerCompanyInfo.name?.trim() ? "" : "error",
      description: innerCompanyInfo.description?.trim() ? "" : "error",
      street: innerCompanyInfo.address?.street?.length ? "" : "error",
      postalCode: innerCompanyInfo.address?.postalCode?.length ? "" : "error",
    });
    if (!isInfoValid) {
      message.error(t("Please fix invalid fields"));
      return;
    }
    await dispatch.companyInfo.updateCompanyInfoAction({
      info: {
        ...innerCompanyInfo,
      },
    });
    setHasValueChanges(false);
    setTimeout(() => {
      navigate(RoutePaths.COMPANY_TRADES);
    }, 500);
  };

  const onGenerateCompanyInfo = async () => {
    setIsBioGenerating(true);
    const companyBioInput: CompanyBioInputType = {
      type: "company",
      sourceLang: convertLangForBackend(i18n.language),
    };
    try {
      const response = await generateCompanyBio(companyBioInput);
      const newCompanyInfo = { ...innerCompanyInfo };
      newCompanyInfo.description = response;
      setInnerCompanyInfo(newCompanyInfo);
    } catch (e) {
      message.error(t("Sorry, something went wrong."));
    }
    setIsBioGenerating(false);
    setHasValueChanges(true);
  };

  return (
    <ContentLayout
      title={t("Company Info")}
      nextButtonOnClick={onSaveCompanyInfo}
      nextButtonText={t("Save and next")}
    >
      <Col span={24} md={12}>
        <Card style={{ background: "none" }}>
          <Typography.Text>{t("Cover image")}</Typography.Text>
          <div style={{ marginTop: 20 }}>
            <Uploader
              allowCrop
              cropAspect={CropAspectEnum.COVER_IMAGE}
              existingMediaArray={[innerCompanyInfo.coverImage].filter((i) => i) as string[]}
              onImagesChange={(i) => {
                changeCoverImage(i);
              }}
              multiple={false}
            />
          </div>
        </Card>
      </Col>
      <Col span={24} md={12}>
        <Card style={{ background: "none" }}>
          <Typography.Text style={{ marginBottom: 20 }}>{t("Company logo")}</Typography.Text>
          <div style={{ marginTop: 20 }}>
            <Uploader
              existingMediaArray={[innerCompanyInfo.logo].filter((i) => i) as string[]}
              onImagesChange={(i) => {
                changeImages(i);
              }}
              multiple={false}
            />
          </div>
        </Card>
      </Col>

      <Col span={24} xl={12}>
        <Typography.Text>{t("Company name")}</Typography.Text>
        <Input
          size="large"
          style={{ marginBottom: "30px" }}
          placeholder={t("Company name")}
          onChange={changeCompanyName}
          value={innerCompanyInfo.name}
          status={
            innerCompanyInfo.name?.length === 0 || innerCompanyInfo.name === null
              ? infoFieldsStatus.name
              : ""
          }
        />
        <Typography.Text>{t("Company description")}</Typography.Text>
        <div style={{ position: "relative" }}>
          {isBioGenerating && (
            <Spin
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                zIndex: "10",
                backgroundColor: "rgba(255,255,255,0.5)",
              }}
            />
          )}
          <Input.TextArea
            size="large"
            style={{ marginBottom: "20px", height: "30vh" }}
            placeholder={t("Company description")}
            onChange={changeCompanyDescription}
            value={innerCompanyInfo.description}
            required={true}
            status={
              innerCompanyInfo.description?.length === 0 || innerCompanyInfo.description === null
                ? infoFieldsStatus.description
                : ""
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          <Tooltip
            placement="bottom"
            title={t("You can generate a new bio as many times as you want!")}
          >
            <Button disabled={isBioGenerating} onClick={onGenerateCompanyInfo} type="primary">
              {t("Generate a new bio for me!")}
            </Button>
          </Tooltip>
        </div>
      </Col>

      <Col xs={24} xl={12}>
        <Typography.Text>{t("Company address")}</Typography.Text>
        <AddressPicker
          address={innerCompanyInfo.address}
          onChange={changeCompanyAddress}
          onStatusUpdate={handleCompanyAddressStatus}
          mapOffset={55}
        />
      </Col>
    </ContentLayout>
  );
};
