import { Colors } from "@app/constants/colors.constants";
import { ElementType } from "react";
import styles from "./HeaderContact.module.scss";

type HeaderContactProps = {
  label: string;
  href?: string;
  icon: ElementType;
};

export const HeaderContact = ({ label, href, icon: Icon }: HeaderContactProps) => {
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} style={{ color: Colors.primary }} />
      {href ? (
        <a href={href} className={styles.link}>
          {label}
        </a>
      ) : (
        label
      )}
    </div>
  );
};
