import { useLocation, matchRoutes } from "react-router-dom";
import { ALL_ROUTES_FLATTEN } from "../constants/routes.constants";

export function useCurrentRoute() {
  const location = useLocation();
  const matches = matchRoutes(
    ALL_ROUTES_FLATTEN.map((route) => ({ path: route.key })),
    location
  );
  const match = matches?.[0];
  if (match) {
    const actualRoute = ALL_ROUTES_FLATTEN.find((route) => route.key === match.route.path);
    if (actualRoute) {
      return actualRoute;
    }
  }

  return ALL_ROUTES_FLATTEN[0];
}
