import { ECandidateApprovalAction, ECandidateRejectionReason } from "@app/types/candidate.types";
import { useQuery } from "@tanstack/react-query";
import {
  CandidatesSAWithPaginationDef,
  GetCandidatesSAParamsDef,
} from "../types/super-admin.candidate.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getCandidatesSA = (params: GetCandidatesSAParamsDef) => {
  return superAdminApi.get<CandidatesSAWithPaginationDef>("/super_admin/candidates", {
    params,
  });
};

export const useCandidatesSA = (
  params: GetCandidatesSAParamsDef,
  options?: {
    refetchInterval?: number;
  }
) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "candidates", params] as const,
    queryFn: ({ queryKey }) => getCandidatesSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: options?.refetchInterval,
  });

export const approveCandidateSA = (
  payload: {
    candidateId: string;
    description: string;
    isVisibleToCompany: boolean;
  } & (
    | {
        action: ECandidateApprovalAction.APPROVE;
      }
    | {
        action: ECandidateApprovalAction.REJECT;
        rejectionReason: ECandidateRejectionReason;
        rejectionMessage: string;
      }
  )
) => {
  return superAdminApi.post(`/super_admin/candidate_approvals`, payload);
};
