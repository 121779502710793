import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { ScreeningQuestions } from "./ScreeningQuestions/ScreeningQuestions";

type ScreeningQuestionsDrawerProps = {
  onClose: () => void;
  open: boolean;
  candidateUserId: string;
  companyId?: string;
};

export const ScreeningQuestionsDrawer = ({
  onClose,
  open,
  candidateUserId,
  companyId
}: ScreeningQuestionsDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer open={open} onClose={onClose} title={t("chat-screening-questions")}>
      <ScreeningQuestions candidateUserId={candidateUserId} companyId={companyId} />
    </Drawer>
  );
};
