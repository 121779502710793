import { MANDATORY_KEY_DATA } from "@app/components/pages/KeyData";
import { RootState } from "@app/store/store";
import { AccountSetupProgress } from "@app/types/account-setup.types";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { useSelector } from "react-redux";

export function useAccountSetupProgress() {
  const companyBenefits = useSelector((state: RootState) => state.companyData.companyBenefits);
  const trades = useSelector((state: RootState) => state.trades.companyTrades);
  const keyData = useSelector((state: RootState) => state.companyData.keyData);
  const projects = useSelector((state: RootState) => state.projects.projects);
  const machines = useSelector((state: RootState) => state.machines.machines);
  const companyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const hasWebsiteLink = useSelector((state: RootState) => state.companyData.hasWebsiteLink);

  const keyDataNames = keyData.map((v) => v.name);

  return {
    steps: {
      companyInfo: !!(
        companyInfo.name &&
        companyInfo.description &&
        companyInfo.address &&
        companyInfo.coordinates?.lat &&
        companyInfo.address?.street &&
        companyInfo.address?.postalCode
      ),
      trades: !!trades.length,
      keyData: MANDATORY_KEY_DATA.every((v) => keyDataNames.includes(v)),
      projects: !!projects.length,
      machines: !!machines.length,
      employeeBenefits: !!companyBenefits.length,
    },
    hasWebsiteLink,
    approvalStatus: companyInfo.approvalStatus || ApprovalStatusEnum.NOT_STARTED,
  } as AccountSetupProgress;
}
