import {
  deleteRecommendationSA,
  getEngagementSA,
  recommendCandidateSA,
} from "@app/features/super-admin/api/super-admin.engagement.api";
import {
  EEngagementType,
  EngagementSADef,
} from "@app/features/super-admin/types/super-admin.engagement.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { Button, message, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type EngagementStatusProps = {
  companyId: string;
  userId?: string;
};

export const EngagementStatus = ({ companyId, userId }: EngagementStatusProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [engagement, setEngagement] = useState<EngagementSADef>();

  const fetchData = useCallback(async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      const response = await getEngagementSA(companyId, userId);
      setEngagement(response.data);
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        message.error({
          content: getErrorMessages(err as ApiFormErrorDef),
          key: "get-engagement",
          duration: 10,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [userId, companyId]);

  const handleOnRecommendCandidate = async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      await recommendCandidateSA(companyId, userId);
      fetchData();
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        message.error({
          content: getErrorMessages(err as ApiFormErrorDef),
          key: "recommend-candidate",
          duration: 10,
        });
      }
      setLoading(false);
    }
  };

  const handleDeleteRecommendation = async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      await deleteRecommendationSA(companyId, userId);
      fetchData();
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        message.error({
          content: getErrorMessages(err as ApiFormErrorDef),
          key: "delete-recommendation",
          duration: 10,
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId, userId]);

  if (loading) {
    return <Spin />;
  }

  if (engagement) {
    return (
      <>
        <p>{engagement.engagementType}</p>
        {engagement.engagementType === EEngagementType.MANUALLY_RECOMMENDED && (
          <Button onClick={handleDeleteRecommendation} type="default">
            {t("Delete")}
          </Button>
        )}
      </>
    );
  }

  return (
    <Button onClick={handleOnRecommendCandidate} type="primary">
      {t("recommend-button")}
    </Button>
  );
};
