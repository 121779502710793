import { HeaderContact } from "@app/components/pages/Candidates/components/CandidateDrawer/components/HeaderInfo/components/HeaderContact/HeaderContact";
import { PageButton } from "@app/components/pages/Candidates/components/CandidateDrawer/components/PageButton/PageButton";
import { ScreeningQuestionsDrawer } from "@app/components/pages/Candidates/components/ScreeningQuestionsDrawer/ScreeningQuestionsDrawer";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { isProd } from "@app/constants/env";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { firebaseConfig } from "@app/firebaseConfig";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";
import { Drawer, Space, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdMail, MdPhone } from "react-icons/md";
import { useSelector } from "react-redux";
import { ApprovalHistoryDrawer } from "./ApprovalHistoryDrawer";
import { CrafthuntChat } from "@app/components/ui/CrafthuntChat";

type CandidateDrawerProps = {
  onClose: () => void;
  open: boolean;
  candidate: CandidateSADef;
};

export const CandidateDrawer = ({ onClose, open, candidate }: CandidateDrawerProps) => {
  const [showScreeningQuestions, setShowScreeningQuestions] = useState(false);
  const [showApprovalHistory, setShowApprovalHistory] = useState(false);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const { i18n, t } = useTranslation();
  const currentTranslations = i18n.getDataByLanguage(i18n.language);

  return (
    <Drawer open={open} onClose={onClose}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Typography.Title level={2}>
          {candidate.workerProfile.firstName} {candidate.workerProfile.lastName}
        </Typography.Title>
        {!!candidate.workerProfile.phone && (
          <HeaderContact
            icon={MdPhone}
            href={`tel:${candidate.workerProfile.phone}`}
            label={candidate.workerProfile.phone}
          />
        )}
        {!!candidate.workerProfile.email && (
          <HeaderContact
            icon={MdMail}
            href={`mailto:${candidate.workerProfile.email}`}
            label={candidate.workerProfile.email}
          />
        )}
        <Space size="small" direction="vertical" style={{ width: "100%" }}>
          <PageButton
            label={t("chat-screening-questions")}
            onClick={() => setShowScreeningQuestions(true)}
          />
          <ScreeningQuestionsDrawer
            open={showScreeningQuestions}
            candidateUserId={candidate.workerProfile.id}
            companyId={candidate.crafthuntCompany.id}
            onClose={() => setShowScreeningQuestions(false)}
          />
          <PageButton label={t("Approval History")} onClick={() => setShowApprovalHistory(true)} />
          <ApprovalHistoryDrawer
            open={showApprovalHistory}
            candidateUserId={candidate.workerProfile.id}
            onClose={() => setShowApprovalHistory(false)}
          />
        </Space>
        {!superToken || !currentTranslations?.translation ? (
          <LoadingSpinner />
        ) : (
          <div style={{ width: "100%", height: "70vh" }}>
            <CrafthuntChat
              isStaging={!isProd()}
              jwt={superToken}
              firebaseConfig={firebaseConfig}
              translations={currentTranslations.translation}
              candidateId={candidate.id}
              lang={convertLangForBackend(i18n.language)}
              viewCandidateChatsAsSuperAdmin
              viewingCandidateWorkerProfileId={candidate.workerProfile.id}
            />
          </div>
        )}
      </Space>
    </Drawer>
  );
};
