import { UserOutlined } from "@ant-design/icons";
import { Button } from "@app/components/ui/Button/Button";
import { LocalForgatPasswordForm } from "@app/features/auth/types/auth.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState, store } from "@app/store/store";
import { Divider, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AuthHeader } from "../AuthHeader/AuthHeader";
import styles from "./ForgotPassword.module.scss";

const FORGOT_KEY = "forgot-password";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = useForm<LocalForgatPasswordForm>();
  const loading = useSelector((state: RootState) => state.loading.models.auth);
  const navigate = useNavigate();
  const { dispatch } = store;

  // Only runs once due to no dependencies
  useEffect(() => {
    message.destroy();
  }, []);

  const handleFinish = async (values: LocalForgatPasswordForm) => {
    try {
      await dispatch.auth.forgotPassword(values);
      message.success({
        content: t("Reset password email sent. Please check your email inbox."),
        key: FORGOT_KEY,
        duration: 10,
      });
      navigate(RoutePaths.LOGIN);
    } catch (error) {
      message.error({ content: t("Sorry, something went wrong."), key: FORGOT_KEY });
    }
  };

  return (
    <>
      <AuthHeader
        title={t("Request new password")}
        description={t(
          "Please enter the email address for the account you forgot your password to."
        )}
      />
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        requiredMark={false}
        data-hs-do-not-collect="true"
      >
        <Form.Item
          name="email"
          label={t("Email")}
          hasFeedback
          rules={[
            {
              type: "email",
              required: true,
              whitespace: true,
              message: t("Invalid email"),
            },
          ]}
        >
          <Input prefix={<UserOutlined />} type="text" size="large" />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button type="primary" htmlType="submit" block loading={loading} size="large">
            {t("Request new password")}
          </Button>
        </Form.Item>
        <Divider />
        <div className={styles.center}>
          <Link to={RoutePaths.LOGIN}>
            <Button type="text" className={styles.underline}>
              {t("Return to sign in")}
            </Button>
          </Link>
        </div>
      </Form>
    </>
  );
};
