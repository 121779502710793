import { CSSProperties, useEffect, useState } from "react";
import styles from "./Svg.module.scss";
import clsx from "clsx";

type SvgProps = {
  image?: string;
  className?: string;
  style?: CSSProperties;
  alt?: string;
  useCurrentColor?: boolean;
};

export const Svg = ({ image, className, style, alt, useCurrentColor }: SvgProps) => {
  const [svg, setSvg] = useState<string>("");
  const containsSvg = image?.includes(".svg");

  useEffect(() => {
    if (containsSvg && !!image) {
      fetch(image)
        .then((res) => res.text())
        .then(setSvg);
    }
  }, [image, containsSvg]);

  if (!containsSvg) {
    return <img src={image || ""} alt={alt} />;
  }

  return (
    <div
      className={clsx({ [styles.svgInline]: useCurrentColor }, className)}
      dangerouslySetInnerHTML={{ __html: svg }}
      style={style}
    />
  );
};
