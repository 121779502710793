import { LockOutlined } from "@ant-design/icons";
import { PASSWORD_REGEX } from "@app/constants/validation.constants";
import { ChangePasswordForm } from "@app/features/auth/types/auth.types";
import { store } from "@app/store/store";
import { Form, Input, message, Modal } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";

type PasswordChangeModalProps = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const PasswordChangeModal = ({ open, setOpen }: PasswordChangeModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<ChangePasswordForm>();
  const [loading, setLoading] = useState(false);
  const { dispatch } = store;

  const passwordRules: Rule[] = [
    {
      required: true,
      pattern: PASSWORD_REGEX,
      message: t(
        "At least 8 characters, including one lowercase letter, one capital letter, one number."
      ),
    },
  ];
  const passwordConfirmRules: Rule[] = [
    {
      required: true,
      message: t("Please confirm your password!"),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("newPassword") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
      },
    }),
  ];

  const handleSave = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    try {
      await dispatch.auth.changePassword({ newPassword: values.newPassword });
      form.resetFields();
      setOpen(false);
      message.success({ content: t("Password changed successfully") });
    } catch (e) {
      if (e instanceof Error && e.message) {
        message.error({ type: "error", content: t("Invalid credentials") });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={t("Change Password")}
      open={open}
      onOk={handleSave}
      confirmLoading={loading}
      onCancel={handleModalCancel}
      okText={t("Done")}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form form={form} layout="vertical" data-hs-do-not-collect="true">
        <Form.Item name="newPassword" label={t("Enter your new password")} rules={passwordRules}>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="***"
            size="large"
            name="newPassword"
          />
        </Form.Item>
        <Form.Item
          name="newPasswordConfirm"
          label={t("Confirm password")}
          hasFeedback
          rules={passwordConfirmRules}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="***"
            size="large"
            name="password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordChangeModal;
