import { Col, Typography, Modal, Row, Spin, Grid } from "antd";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/store";
import qrCodePhone from "@app/assets/QR_code_admin_dashboard.png";
import Arrow from "@app/assets/Arrow.png";
import styles from "./DownloadAppModal.module.scss";

const { Title } = Typography;
const { useBreakpoint } = Grid;

type DownloadAppModalProps = {
  open: boolean;
  close: () => void;
};

export const DownloadAppModal = ({ open, close }: DownloadAppModalProps) => {
  const { t } = useTranslation();
  const magicLink = useSelector((state: RootState) => state.auth.magicLink);
  const screens = useBreakpoint();

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      closable={false}
      cancelButtonProps={{ style: { display: "none", pointerEvents: "none" } }}
      width={1000}
      style={{ paddingBottom: 100 }}
      centered
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
      okText={t("Done")}
      okButtonProps={{ size: "large" }}
    >
      <Row justify="center" align="middle" gutter={60}>
        <Col xs={12} md={12}>
          <Title level={screens.md ? 2 : 3}>
            {t("Log into the mobile app with your company profile, by scanning this QR code")}
          </Title>
          <img className={styles.arrow} width={200} src={Arrow} />
        </Col>
        <Col xs={10} md={8}>
          <div className={styles.imageContainer}>
            <img src={qrCodePhone} className={styles.image} />
            {magicLink ? (
              <QRCode
                value={magicLink}
                className={styles.qrCode}
                size={256}
                viewBox={`0 0 256 256`}
              />
            ) : (
              <Row align="middle" justify="center" style={{ height: 256 }}>
                <Col>
                  <Spin />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
