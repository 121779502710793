import { CandidateDef, UpdateCandidateDef } from "@app/types/candidate.types";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type InternalNoteProps = {
  candidate: CandidateDef;
  updateCandidate: (payload: UpdateCandidateDef) => void;
};

export const InternalNote = ({ candidate, updateCandidate }: InternalNoteProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(candidate.internalNote || "");
  const hasChanged = value !== (candidate.internalNote || "");

  const updateCandidateInternalNote = async () => {
    if (!hasChanged) {
      return;
    }
    updateCandidate({ internalNote: value || null });
  };

  return (
    <div>
      <label htmlFor="candidate-internal-note">{t("internal-note-title")}</label>
      <TextArea
        id="candidate-internal-note"
        rows={8}
        placeholder={t("internal-note-placeholder")}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={updateCandidateInternalNote}
      />
    </div>
  );
};
