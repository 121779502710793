import { bulkUpdateJobQuestions } from "@app/api/job-questions.api";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import HttpStatusCode from "@app/constants/http-status-code.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { mapUndefinedToNull } from "@app/helpers/form.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { useUnsavedPrompt } from "@app/hooks/useUnsavedPrompt";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import { CreateJobAdDef, EJobAdStatus, JobAdNavigationState } from "@app/types/job-ads.types";
import { JobQuestionsFormDef } from "@app/types/job-questions.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Alert, Col, Form, Modal, Row, Tabs, TabsProps, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { jobAdDetailsHeaderItems } from "./components/JobAdDetailsHeaderItems/JobAdDetailsHeaderItems";
import { JobAdForm } from "./components/JobAdForm/JobAdForm";
import { JobQuestionForm } from "./components/JobQuestionForm/JobQuestionForm";
import { useFetchJobAdById } from "./hooks/useFetchJobAdById";

const NEW_PATH = "new";

enum TAB_KEYS {
  INFO = "info",
  QUESTIONS = "questions",
}

export const JobAdDetails = () => {
  const { id } = useParams();
  const { state } = useLocation() as { state?: JobAdNavigationState };
  const isCreateNewJobAd = id === NEW_PATH;
  const duplicateJobAdId = state?.duplicateJobAdId;
  const isDuplicateJobAd = !!duplicateJobAdId;
  const { t, i18n } = useTranslation();
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [hasErrors, setHasErrors] = useState(false);
  const loading = useSelector((state: RootState) => state.loading.models.jobAds);
  const navigate = useNavigate();
  const [jobAdForm] = Form.useForm<CreateJobAdDef>();
  const [questionsForm] = Form.useForm<JobQuestionsFormDef>();
  const { jobAdData, isFetchingData, setJobAdData } = useFetchJobAdById(
    duplicateJobAdId || id,
    jobAdForm,
    questionsForm,
    isCreateNewJobAd,
    isDuplicateJobAd
  );
  const [activeTab, setActiveTab] = useState(TAB_KEYS.INFO);
  const { dispatch } = store;
  const isJobAdPublished = jobAdData?.status === EJobAdStatus.PUBLISHED;
  const hasJobAdBeenPublished = isJobAdPublished || !!jobAdData?.unpublishedAt;

  useUnsavedPrompt(
    t("You have unsaved changes, are you sure you want to leave?"),
    hasChangedValues
  );

  useEffect(() => {
    if (loading) {
      message.loading({ content: t("Saving..."), key: ModelNames.JOB_ADS, duration: 1000 });
    }
  }, [loading]);

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, [redirectUrl]);

  const onChangeTab = (key: string) => {
    setActiveTab(key as TAB_KEYS);
  };

  const onSaveJobAd = async () => {
    const rawData = jobAdForm.getFieldsValue();
    const documentsRequired = questionsForm.getFieldValue("documentsRequired");
    const data = { ...mapUndefinedToNull(rawData), documentsRequired };
    if (jobAdData?.id) {
      const updatedJobAd = await dispatch.jobAds.updateJobAdAction({
        ...data,
        id: jobAdData.id,
        lang: convertLangForBackend(i18n.language),
      });
      setJobAdData(updatedJobAd);
      jobAdForm.setFieldsValue(updatedJobAd);
      triggerAnalyticsEvent("job_ad_edited", updatedJobAd);
      if (isJobAdPublished && updatedJobAd.status === EJobAdStatus.DRAFT) {
        triggerAnalyticsEvent("job_ad_draft", updatedJobAd);
      }
      if (!isJobAdPublished && updatedJobAd.status === EJobAdStatus.PUBLISHED) {
        triggerAnalyticsEvent("job_ad_published", updatedJobAd);
      }
      return {
        status: HttpStatusCode.OK,
        data: updatedJobAd,
      };
    }
    const createdJobAd = await dispatch.jobAds.addJobAdAction({
      ...data,
      lang: convertLangForBackend(i18n.language),
    });
    setJobAdData(createdJobAd);
    jobAdForm.setFieldsValue(createdJobAd);
    triggerAnalyticsEvent("job_ad_created", createdJobAd);
    return {
      status: HttpStatusCode.CREATED,
      data: createdJobAd,
    };
  };

  const onSaveJobQuestions = async (jobAdId: string) => {
    const data = questionsForm.getFieldsValue();
    if (data.questions) {
      const updatedJobQuestions = await bulkUpdateJobQuestions({
        jobAdId,
        items: data.questions,
        lang: convertLangForBackend(i18n.language),
      });
      questionsForm.setFieldsValue({ questions: updatedJobQuestions });
      message.success({ content: t("Saved!"), key: ModelNames.JOB_ADS, duration: 1 });
    }
  };

  const handleAllFormFinished = async () => {
    try {
      await jobAdForm.validateFields();
    } catch (err) {
      onChangeTab(TAB_KEYS.INFO);
      message.error({
        content: t("Please fix invalid fields"),
        key: ModelNames.JOB_ADS,
        duration: 1,
      });
      return;
    }
    try {
      await questionsForm.validateFields();
    } catch (err) {
      onChangeTab(TAB_KEYS.QUESTIONS);
      message.error({
        content: t("Please fix invalid fields"),
        key: ModelNames.JOB_ADS,
        duration: 1,
      });
      return;
    }

    try {
      // Create/Update Job Ad
      const response = await onSaveJobAd();

      // Save Job Questions
      await onSaveJobQuestions(response.data.id);

      // When both are done and successful
      setHasChangedValues(false);
      message.success({ content: t("Saved!"), key: ModelNames.JOB_ADS, duration: 1 });
      if (response.status === HttpStatusCode.CREATED) {
        setRedirectUrl(
          generatePath(RoutePaths.JOB_AD_DETAILS, {
            id: response.data.id,
          })
        );
      }
    } catch (err) {
      message.destroy(ModelNames.JOB_ADS);
      Modal.error({
        title: t("Sorry, something went wrong."),
        content: getErrorMessages(err as ApiFormErrorDef),
      });
    }
  };

  const handleAllFormChanged = () => {
    // Use setTimeout here to avoid outOfDate error when checking form for errors.
    // See https://github.com/ant-design/ant-design/issues/26747#issuecomment-693165910
    setTimeout(() => {
      const foundError = [jobAdForm, questionsForm].some((formInstances) =>
        formInstances.getFieldsError().some(({ errors }) => errors.length)
      );
      setHasErrors(foundError);
      setHasChangedValues(true);
    });
  };

  const tabItems: TabsProps["items"] = [
    {
      key: TAB_KEYS.INFO,
      label: t("Info"),
      children: (
        <JobAdForm
          form={jobAdForm}
          onFinishFailed={handleAllFormFinished}
          isFetchingData={isFetchingData}
          isCreateNewJobAd={isCreateNewJobAd}
          hasJobAdBeenPublished={hasJobAdBeenPublished}
          onCoverImageChange={handleAllFormChanged}
        />
      ),
      forceRender: true,
    },
    {
      key: TAB_KEYS.QUESTIONS,
      label: t("Screening Questions"),
      children: <JobQuestionForm form={questionsForm} isJobAdPublished={isJobAdPublished} />,
      forceRender: true,
    },
  ];

  return (
    <Form.Provider onFormFinish={handleAllFormFinished} onFormChange={handleAllFormChanged}>
      <ContentLayout
        breadcrumbs={[
          {
            url: RoutePaths.JOB_ADS,
            title: t("Job ads"),
          },
        ]}
        title={isCreateNewJobAd ? t("New job ad") : t("Job ad details")}
        headerItems={jobAdDetailsHeaderItems({
          onSaveJobAd,
          form: jobAdForm,
          hasChangedValues,
          hasErrors,
        })}
      >
        {(loading || isFetchingData) && <LoadingSpinner />}
        <Col span={24}>
          <Row gutter={[24, 24]} justify="center">
            <Col span={24} style={{ maxWidth: 750 }}>
              {isDuplicateJobAd && (
                <Alert
                  message={t("Duplicating job ad")}
                  description={t("Make a change and save it as a new job ad")}
                  type="info"
                  showIcon
                />
              )}
              <Tabs activeKey={activeTab} onChange={onChangeTab} items={tabItems} />
            </Col>
          </Row>
        </Col>
      </ContentLayout>
    </Form.Provider>
  );
};
