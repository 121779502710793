import { RoutePaths } from "@app/features/routes/types/routes.types";
import { Dispatch, RootState } from "@app/store/store";
import { ECandidateStatus } from "@app/types/candidate.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Tabs, TabsProps } from "antd";
import { parseAsStringEnum, useQueryState } from "nuqs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import SuperAdminPasswordModal from "../SuperAdminPasswordModal/SuperAdminPasswordModal";
import { Approvals } from "./components/Approvals/Approvals";
import { Candidates } from "./components/Candidates/Candidates";
import { Companies } from "./components/Companies/Companies";
import { Jobs } from "./components/Jobs/Jobs";
import { Users } from "./components/Users/Users";
import { UserModalGlobal } from "./components/Users/components/UserModal/UserModalGlobal";

enum TAB_KEYS {
  COMPANIES = "companies",
  USERS = "users",
  JOBS = "jobs",
  CANDIDATES = "candidates",
  APPROVALS = "approvals",
}

const queryClient = new QueryClient();

export const SuperAdminStuff = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [approvalsCount, setApprovalsCount] = useState(0);
  const loadingSuperAdminCheck = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.superAdminCheck
  );
  const isSuperAdmin = useSelector((state: RootState) => state.auth.parsedToken?.isSuperAdmin);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const dispatch = useDispatch<Dispatch>();
  const [activeTab, setActiveTab] = useQueryState(
    "tab",
    parseAsStringEnum(Object.values(TAB_KEYS)).withDefault(TAB_KEYS.COMPANIES)
  );
  // modals
  const [superAdminKeyModalOpen, setSuperAdminKeyModalOpen] = useState(false);

  const redirectToDashboard = () => {
    navigate(RoutePaths.DASHBOARD);
  };

  useMount(() => {
    if (superToken) {
      dispatch.tags.getAllCertificates();
    }
  });

  useEffect(() => {
    if (!loadingSuperAdminCheck && !superToken) {
      if (isSuperAdmin) {
        setSuperAdminKeyModalOpen(true);
      } else {
        redirectToDashboard();
      }
    }
  }, [loadingSuperAdminCheck, isSuperAdmin, superToken]);

  const tabItems: TabsProps["items"] = [
    {
      key: TAB_KEYS.COMPANIES,
      label: t("Companies"),
      children: <Companies />,
    },
    {
      key: TAB_KEYS.USERS,
      label: t("Users"),
      children: <Users />,
    },
    {
      key: TAB_KEYS.JOBS,
      label: t("Job ads"),
      children: <Jobs />,
    },
    {
      key: TAB_KEYS.CANDIDATES,
      label: t("applications-label"),
      children: <Candidates excludedStatuses={[ECandidateStatus.NEEDS_APPROVAL]} />,
    },
    {
      key: TAB_KEYS.APPROVALS,
      label: t("approvals-label") + ` (${approvalsCount})`,
      children: (
        <Approvals isActive={activeTab === TAB_KEYS.APPROVALS} onCountChange={setApprovalsCount} />
      ),
      forceRender: true,
    },
  ];

  return (
    <QueryClientProvider client={queryClient}>
      <ContentLayout title={t("Super admin stuff")}>
        {superToken && (
          <Tabs
            activeKey={activeTab}
            onChange={(tabKey) => setActiveTab(tabKey as TAB_KEYS)}
            items={tabItems}
            style={{ width: "100%" }}
          />
        )}
        <SuperAdminPasswordModal
          open={superAdminKeyModalOpen}
          setOpen={setSuperAdminKeyModalOpen}
          onCancel={redirectToDashboard}
        />
      </ContentLayout>
      <UserModalGlobal />
    </QueryClientProvider>
  );
};
