import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { updateCompanySettingsSA } from "@app/features/super-admin/api/super-admin.company.api";
import {
  CompanyFormSADef,
  CompanySADef,
  ECompanyAdvertisement,
} from "@app/features/super-admin/types/super-admin.company.types";
import { useMutation } from "@tanstack/react-query";
import { Form, message, Modal, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyAdvertisements } from "./components/CompanyAdvertisements/CompanyAdvertisements";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";

enum TAB_KEYS {
  INFO = "info",
  ADVERTISEMENTS = "advertisements",
}

type CompanyModalProps = {
  open: boolean;
  selectedCompany?: CompanySADef;
  onSave: () => void;
  onCancel: () => void;
};

export const CompanyModal = ({ open, selectedCompany, onSave, onCancel }: CompanyModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<CompanyFormSADef>();
  const [activeTab, setActiveTab] = useState<TAB_KEYS>(TAB_KEYS.INFO);

  const initialValues = {
    ...selectedCompany,
    enabledAdvertisements:
      selectedCompany?.enabledAdvertisements.reduce(
        (acc, item) => ({ ...acc, [item]: true }),
        {}
      ) || {},
  };

  const changeCompanySettings = useMutation({
    mutationFn: () => {
      const formValues = form.getFieldsValue();
      // Transform the object of enabledAdvertisements to array of enabled ones
      const enabledAdvertisements = Object.entries(formValues.enabledAdvertisements)
        .filter(([, value]) => value)
        .map(([key]) => key as ECompanyAdvertisement);

      return selectedCompany
        ? updateCompanySettingsSA({ ...selectedCompany, ...formValues, enabledAdvertisements })
        : Promise.reject();
    },
    onSuccess: () => {
      message.success("Company settings updated");
      onSave();
    },
    onError: () => {
      message.error("Sorry, something went wrong.");
    },
  });

  useEffect(() => {
    if (selectedCompany) {
      form.resetFields();
    }
  }, [JSON.stringify(selectedCompany)]);

  const onChangeTab = (key: string) => {
    setActiveTab(key as TAB_KEYS);
  };

  const handleOnCancel = () => {
    onCancel();
  };

  const handleReset = () => {
    form.resetFields();
    setActiveTab(TAB_KEYS.INFO);
  };

  return (
    <Modal
      title={selectedCompany?.name}
      open={open}
      onCancel={handleOnCancel}
      onOk={() => changeCompanySettings.mutate()}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
      forceRender
      destroyOnClose
      afterClose={handleReset}
    >
      {selectedCompany && (
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          labelAlign="right"
          form={form}
          initialValues={initialValues}
          data-hs-do-not-collect="true"
        >
          {changeCompanySettings.isPending && <LoadingSpinner />}

          <Tabs
            activeKey={activeTab}
            onChange={onChangeTab}
            items={[
              {
                key: TAB_KEYS.INFO,
                label: t("Info"),
                children: <CompanyForm />,
                forceRender: true,
                destroyInactiveTabPane: false,
              },
              {
                key: TAB_KEYS.ADVERTISEMENTS,
                label: "Advertisements",
                children: <CompanyAdvertisements />,
                forceRender: true,
                destroyInactiveTabPane: false,
              },
            ]}
          />
        </Form>
      )}
    </Modal>
  );
};
