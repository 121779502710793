import { MenuItemGroupType, MenuItemType, SubMenuType } from "antd/lib/menu/hooks/useItems";

export enum MainRoutePaths {
  LOGIN = "/login",
  SIGN_UP = "/signup",
  FORGOT_PASSWORD = "/forgot-password",
  DASHBOARD = "/dashboard",
  COMPANY_INFO_SUBMENU = "company-info-submenu",
}

export const RoutePaths = Object.freeze({
  LOGIN: MainRoutePaths.LOGIN,
  SIGN_UP: MainRoutePaths.SIGN_UP,
  FORGOT_PASSWORD: MainRoutePaths.FORGOT_PASSWORD,
  DASHBOARD: MainRoutePaths.DASHBOARD,
  COMPANY_INFO_SUBMENU: MainRoutePaths.COMPANY_INFO_SUBMENU,

  // Nested routes
  COMPANY_INFO: `${MainRoutePaths.DASHBOARD}/company-information`,
  COMPANY_TRADES: `${MainRoutePaths.DASHBOARD}/company-trades`,
  KEY_DATA: `${MainRoutePaths.DASHBOARD}/key-data`,
  CONSTRUCTION_PROJECTS: `${MainRoutePaths.DASHBOARD}/construction-projects`,
  EQUIPMENT_AND_MACHINERY: `${MainRoutePaths.DASHBOARD}/equipment-and-machinery`,
  EMPLOYEE_BENEFITS: `${MainRoutePaths.DASHBOARD}/employee-benefits`,
  JOB_ADS: `${MainRoutePaths.DASHBOARD}/job-ads`,
  JOB_AD_DETAILS: `${MainRoutePaths.DASHBOARD}/job-ads/:id`,
  JOB_AD_NEW: `${MainRoutePaths.DASHBOARD}/job-ads/new`,
  CANDIDATES: `${MainRoutePaths.DASHBOARD}/candidates`,
  CHAT: `${MainRoutePaths.DASHBOARD}/chat`,
  BAUGPT: `${MainRoutePaths.DASHBOARD}/baugpt`,
  SUBMIT_FOR_REVIEW: `${MainRoutePaths.DASHBOARD}/submit-for-review`,
  SUPER_ADMIN_STUFF: `${MainRoutePaths.DASHBOARD}/super-admin-stuff`,
  SETTINGS: `${MainRoutePaths.DASHBOARD}/settings`,
});

export type RoutePathsValue = (typeof RoutePaths)[keyof typeof RoutePaths];

export type AntMenuItemType = MenuItemType | SubMenuType | MenuItemGroupType;

export type RouteDef = {
  key: string;
  parentKey?: string;
  component?: () => JSX.Element;
  description?: string;
  children?: RouteDef[];
  hideInMenu?: boolean;
  hasFeatureFlag?: boolean;
  fullHeight?: boolean;
  expandIcon?: JSX.Element;
  icon?: MenuItemType["icon"];
  isNew?: boolean;
} & AntMenuItemType;
