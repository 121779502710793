import { ApprovalStatusEnum } from "../../../types/company-info.types";
import { PaginationRequestDef, PaginationResponseDef } from "../../../types/pagination.types";

export interface LoginAsAdminDef {
  targetWorkerProfileId: string;
  targetEmployeeId: string;
  targetCompanyId: string;
}
export interface LoginUnclaimCompanyDef {
  targetCompanyId: string;
  controlCompany: boolean;
}

export interface CompaniesSAColumnsDataDef {
  key: string;
  name: JSX.Element;
  approvalStatus: JSX.Element;
  contactEmail: string;
  hasPaidForJobAds: JSX.Element;
  operation: JSX.Element;
}

export enum ECompanyAdvertisement {
  EBAY = "ebay-kleinanzeigen",
  STEPSTONE = "stepstone",
  INDEED = "indeed",
  CURATO = "curato",
  OLX = "olx",
  TIKTOK = "tiktok",
  GOOGLE_JOBS = "google-jobs",
  META = "meta",
}

export interface CompanySADef {
  id: string;
  name: string;
  slug: string;
  contactEmail: string;
  contactPhone: string;
  hasPaidForJobAds: boolean;
  hasManualCandidateApproval: boolean;
  hasIntegrations: boolean;
  isTestCompany: boolean;
  approvalStatus: ApprovalStatusEnum;
  internalComment: string;
  enabledAdvertisements: ECompanyAdvertisement[];
  admins: CompanyAdminDef[];
}
export type CompanyFormSADef = Omit<CompanySADef, "enabledAdvertisements" | "admins"> & {
  enabledAdvertisements: Record<ECompanyAdvertisement, boolean>;
};

export interface CompanyAdminDef {
  targetWorkerProfileId: string;
  firstName: string;
  lastName: string;
  picture: string | null;
  targetEmployeeId: string;
  targetCompanyId: string;
}
export interface CompanyResourceSAUpdateDto {
  name?: string | null;
  description?: string | null;
  contactEmail?: string | null;
  lang?: string | null;
  coverImage?: string | null;
  logo?: string | null;
  address?: string | null;
  coordinate?: string | null;
  approvalStatus?: string | null;
  hasPaidForJobAds: boolean;
  hasManualCandidateApproval: boolean;
  hasIntegrations: boolean;
  isTestCompany: boolean;
}

export type GetCompaniesSAParamsDef = PaginationRequestDef & {
  keyword?: string;
  isPaying?: boolean;
};

export type CompaniesSAWithPaginationDef = PaginationResponseDef<CompanySADef>;

export type CreateCompanySADef = {
  name: string;
};

export type CreateCompanySAResponseDef = {
  id: string;
  name: string;
};
