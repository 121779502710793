import { useQuery } from "@tanstack/react-query";
import {
  CandidateApprovalsSAWithPaginationDef,
  GetCandidateApprovalSAParamsDef,
} from "../types/super-admin.candidate.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

const getCandidateApprovals = (params: GetCandidateApprovalSAParamsDef) => {
  return superAdminApi.get<CandidateApprovalsSAWithPaginationDef>(
    "/super_admin/candidate_approvals",
    {
      params,
    }
  );
};

export const useCandidateApprovalsSA = (params: GetCandidateApprovalSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "candidate-approvals", params] as const,
    queryFn: ({ queryKey }) => getCandidateApprovals(queryKey[3]).then(({ data }) => data),
  });
