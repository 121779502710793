import { getJobAd } from "@app/api/job-ads.api";
import { getJobQuestionsByJobAdId } from "@app/api/job-questions.api";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { ModelNames } from "@app/store/models/models";
import { EJobAdStatus, JobAdDef, JobAdFormDef } from "@app/types/job-ads.types";
import { JobQuestionsFormDef } from "@app/types/job-questions.types";
import { FormInstance, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useFetchJobAdById = (
  id: string | undefined,
  jobAdForm: FormInstance<JobAdFormDef>,
  questionsForm: FormInstance<JobQuestionsFormDef>,
  isCreateNewJobAd: boolean,
  isDuplicateJobAd: boolean
) => {
  const [jobAdData, setJobAdData] = useState<JobAdDef>();
  const [isFetchingData, setIsFetchingData] = useState(!isCreateNewJobAd);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchJobAdById = async (id: string) => {
      try {
        // Fetch job ad
        const fetchedJobAd = await getJobAd(id);
        if (isDuplicateJobAd) {
          fetchedJobAd.id = "";
          fetchedJobAd.slug = "";
          fetchedJobAd.unpublishedAt = null;
          fetchedJobAd.status = EJobAdStatus.DRAFT;
        }
        setJobAdData(fetchedJobAd);
        jobAdForm.setFieldsValue(fetchedJobAd);

        // Fetch questions for job ad
        const fetchedJobQuestions = await getJobQuestionsByJobAdId(id);
        if (isDuplicateJobAd) {
          fetchedJobQuestions.forEach((item) => {
            item.id = undefined;
          });
        }
        questionsForm.setFieldsValue({
          documentsRequired: fetchedJobAd.documentsRequired,
          questions: fetchedJobQuestions,
        });
      } catch (err) {
        message.error({ content: t("Sorry, something went wrong."), key: ModelNames.JOB_ADS });
        navigate(RoutePaths.JOB_ADS, { replace: true });
      } finally {
        setIsFetchingData(false);
      }
    };
    if (typeof id === "string" && (!isCreateNewJobAd || isDuplicateJobAd)) {
      fetchJobAdById(id);
    }
  }, [id, isDuplicateJobAd]);

  return { jobAdData, setJobAdData, isFetchingData };
};
