import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { RootState, store } from "@app/store/store";
import {
  ECandidateStatus,
  ECandidateStatusType,
  KanbanSettingsFormDef,
} from "@app/types/candidate.types";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CandidateStatusIcon } from "../CandidateStatusIcon/CandidateStatusIcon";

type KanbanSettingsModalProps = {
  open: boolean;
  onSaved: () => void;
  onCancel: () => void;
};

export const KanbanSettingsModal = ({ open, onSaved, onCancel }: KanbanSettingsModalProps) => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const [form] = Form.useForm<KanbanSettingsFormDef>();
  const allStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const customCandidateStatuses = allStatuses.filter(
    (candidateStatus) => candidateStatus.status === ECandidateStatus.CUSTOM
  );
  const initialValues: KanbanSettingsFormDef = {
    statuses: customCandidateStatuses,
  };
  const hardcodedStatuses = Object.values(ECandidateStatus).filter(
    (status) => status !== ECandidateStatus.NEEDS_APPROVAL
  );

  const getStatusName = (statusType: string) => {
    const companyCandidateStatus = allStatuses.find(
      (candidateStatus) => candidateStatus.status === statusType
    );
    return companyCandidateStatus?.name ?? "status-name-placeholder";
  };

  const handleSave = async (values: KanbanSettingsFormDef) => {
    await dispatch.candidates.updateCandidateStatusesAction(values.statuses);
    onSaved();
    form.resetFields();
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  const statuses = Form.useWatch("statuses", form);
  const interviewStatuses = statuses?.filter(
    (status) => status.type === ECandidateStatusType.INTERVIEW
  );
  const saveDisabled = !interviewStatuses || interviewStatuses.length === 0;

  return (
    <Modal
      title={t("kanban-settings")}
      width={620}
      open={open}
      onOk={form.submit}
      onCancel={handleOnCancel}
      cancelText={t("Cancel")}
      okText={t("Save")}
      okButtonProps={{
        htmlType: "submit",
        disabled: saveDisabled,
      }}
    >
      <Typography.Title level={5} style={{ marginBottom: 20 }}>
        {t("hiring-process-title")}
      </Typography.Title>
      <Space direction="vertical" style={{ width: "100%", marginBottom: 20 }}>
        {hardcodedStatuses.map((status) => {
          if (status !== ECandidateStatus.CUSTOM) {
            return (
              <Input
                key={status}
                prefix={<CandidateStatusIcon candidateStatusName={getStatusName(status)} />}
                disabled
                value={t(getStatusName(status))}
              />
            );
          }
          return (
            <Fragment key={status}>
              <Divider orientation="left" plain orientationMargin={0}>
                {t("custom-statuses-title")}
              </Divider>
              <Form
                form={form}
                name="kanban-settings"
                initialValues={initialValues}
                onFinish={handleSave}
                autoComplete="off"
                data-hs-do-not-collect="true"
              >
                <Form.List name="statuses">
                  {(fields, { add, remove, move }) => {
                    return (
                      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <Row key={key} gutter={4}>
                              <Col flex="auto">
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  noStyle
                                  rules={[
                                    { required: true, message: t("Required") },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (!value) {
                                          return Promise.resolve();
                                        }
                                        const otherStatuses = [
                                          ...getFieldValue("statuses"),
                                        ] as KanbanSettingsFormDef["statuses"];
                                        // remove existing item from options
                                        otherStatuses.splice(name, 1);
                                        // check if other statuses are identical in name
                                        if (otherStatuses.find((other) => other?.name === value)) {
                                          return Promise.reject(
                                            new Error(t("No duplicate status"))
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Input placeholder={t("status-name-placeholder")} />
                                </Form.Item>
                              </Col>
                              <Col span={7}>
                                <Form.Item noStyle required name={[name, "type"]}>
                                  <SelectAutocomplete size="middle" sortAlphabetically={false}>
                                    {Object.values(ECandidateStatusType).map((type) => {
                                      return (
                                        <Select.Option
                                          key={type}
                                          value={type}
                                          label={t(`candidate-status-type-${type}`)}
                                        >
                                          {t(`candidate-status-type-${type}`)}
                                        </Select.Option>
                                      );
                                    })}
                                  </SelectAutocomplete>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => move(index, index - 1)}
                                  disabled={index === 0}
                                >
                                  <ArrowUpOutlined />
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => move(index, index + 1)}
                                  disabled={index === fields.length - 1}
                                >
                                  <ArrowDownOutlined />
                                </Button>
                              </Col>
                              <Col>
                                <Button onClick={() => remove(name)}>
                                  <DeleteOutlined />
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                        <Form.Item noStyle>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                name: "",
                                status: ECandidateStatus.CUSTOM,
                                type: ECandidateStatusType.OTHER,
                              })
                            }
                            block
                            icon={<PlusOutlined />}
                          >
                            {t("Add status")}
                          </Button>
                        </Form.Item>
                        {saveDisabled && (
                          <Typography.Text type="danger">
                            {t("candidate-status-warning-tooltip")}
                          </Typography.Text>
                        )}
                      </Space>
                    );
                  }}
                </Form.List>
              </Form>
              <Divider />
            </Fragment>
          );
        })}
      </Space>
    </Modal>
  );
};
