import { Divider, Typography } from "antd";

const { Title, Paragraph } = Typography;

type AuthHeaderProps = {
  title: string;
  description?: string;
};

export const AuthHeader = ({ title, description }: AuthHeaderProps) => {
  return (
    <div>
      <Title level={2}>{title}</Title>
      {!!description && <Paragraph>{description}</Paragraph>}
      <Divider />
    </div>
  );
};
