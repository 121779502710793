import { useTranslation } from "react-i18next";
import { Logo } from "@app/components/ui/Logo/Logo";
import styles from "./Maintenance.module.scss";
import professions_community from "@app/assets/Professions_Community.png";

export const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        <Logo width={215} />
      </div>
      <p className={styles.text}>
        {t("We are busy updating Crafthunt for you. Please check back soon.")}
        <br />
        <strong>(25 November 2022 between 08:00 and 12:00 GMT+1)</strong>
      </p>
      <img className={styles.image} src={professions_community} alt="Professions_Community" />
    </div>
  );
};
