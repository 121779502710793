import { DateFormats } from "@app/constants/date.constants";
import { AddressDef } from "@app/types/address.types";
import moment from "moment-timezone";
import {
  AdminUserFormDef,
  CertificateFormDef,
  CreateCertificateDef,
  CreateTimelineDef,
  EditCertificateDef,
  EditTimelineDef,
  EditUserDef,
  TimelineFormDef,
  UserFormDef,
  UserStatsDef,
  UserStatsFormDef,
  UserStatsResponseDef,
  UsersSAResponseDef,
} from "../types/super-admin.user.types";
import { mapApiFilesToForm, mapFilesToApi } from "./file.helper";

export const mapUserToForm = (user: UsersSAResponseDef): UserFormDef => {
  return {
    id: user.id,
    firstName: user.firstName || " ",
    lastName: user.lastName || " ",
    email: user.email,
    phone: user.phone,
    description: user.description,
    jobTitleId: user.jobTitleId,
    profileTradesId: user.profileTradesId,
    isReviewed: user.isReviewed,
    isVerified: user.isVerified,
    isAnonymous: user.isAnonymous,
    isTemporary: user.isTemporary,
    isLocked: user.isLocked,
    getScheduledJobRecommendations: user.getScheduledJobRecommendations,
    isCompanyAdmin: user.isCompanyAdmin,
    birthday: user.birthday ? moment(user.birthday) : null,
    geoSearchAddress:
      user.geoSearchAddress && Object.values(user.geoSearchAddress).length
        ? {
            address: user.geoSearchAddress as AddressDef,
            coordinates: {
              lat: Number(user.geoSearchAddress.lat),
              lng: Number(user.geoSearchAddress.lng),
            },
          }
        : null,
    address:
      user.address && user.coordinate
        ? {
            address: user.address,
            coordinates: {
              lat: Number(user.coordinate.lat),
              lng: Number(user.coordinate.lng),
            },
          }
        : null,
    medias: mapApiFilesToForm(user.medias),
    internalComment: user.internalComment,
    externalLinks: user.externalLinks,
    hubspotContactId: user.hubspotContactId,
  };
};

export const mapAdminUserToApi = (user: AdminUserFormDef): Partial<EditUserDef> => {
  return {
    ...user,
    email: user.email || undefined,
    phone: user.phone || undefined,
    firstName: user.firstName?.trim() || " ",
    lastName: user.lastName?.trim() || " ",
  };
};

export const mapUserToApi = (user: UserFormDef): Partial<EditUserDef> => {
  return {
    ...user,
    email: user.email || undefined,
    phone: user.phone || undefined,
    firstName: user.firstName?.trim() || " ",
    lastName: user.lastName?.trim() || " ",
    description: user.description?.trim() || null,
    birthday: user.birthday?.format(DateFormats.D_M_Y_API) || null,
    medias: user.medias ? mapFilesToApi(user.medias) : [],
    geoSearchAddress: user.geoSearchAddress?.address
      ? {
          ...user.geoSearchAddress.address,
          ...user.geoSearchAddress.coordinates,
        }
      : null,
    address: user.address?.address || null,
    coordinate: user.address?.coordinates || null,
  };
};

export const mapTimelineToApi = (
  timeline: TimelineFormDef
): CreateTimelineDef | EditTimelineDef => {
  return {
    ...timeline,
    startDate: timeline.startDate.format(DateFormats.D_M_Y_API),
    endDate: timeline.endDate?.format(DateFormats.D_M_Y_API) || null,
    companyId: timeline.companyId?.value || "",
    address: timeline.address?.address || null,
    coordinate: timeline.address?.coordinates || null,
  };
};

export const mapCertificateToApi = (
  certificate: CertificateFormDef
): CreateCertificateDef | EditCertificateDef => {
  return {
    ...certificate,
    issueDate: certificate.issueDate?.format(DateFormats.D_M_Y_API) || null,
    address: certificate.address?.address || null,
    coordinate: certificate.address?.coordinates || null,
  };
};

export const mapStatsToForm = (stats: UserStatsResponseDef["data"]): UserStatsFormDef => {
  let cloneValues = { ...stats };
  for (const [key, value] of Object.entries(cloneValues)) {
    if (key === "languages") {
      if (!Array.isArray(value)) {
        cloneValues[key] = [];
      }
    } else if (value == null || typeof value === "string") {
      cloneValues = {
        ...cloneValues,
        [key]: Number(value || 0),
      };
    }
  }
  return cloneValues as unknown as UserStatsFormDef;
};
export const mapStatsToApi = (stats: UserStatsFormDef): UserStatsDef => {
  let cloneValues = { ...stats };
  for (const [key, value] of Object.entries(cloneValues)) {
    if (key === "languages") {
      if (!Array.isArray(value)) {
        cloneValues[key] = [];
      }
    } else if (value == null) {
      cloneValues = {
        ...cloneValues,
        [key]: 0,
      };
    }
  }
  return cloneValues as UserStatsDef;
};
