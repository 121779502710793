import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { CandidateRejectionFormDef, ECandidateRejectionReason } from "@app/types/candidate.types";
import { Form, Input, Modal, Select, Typography } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

type RejectionModalProps = {
  open: boolean;
  isFromRecommendedStatus: boolean;
  onReject: (data: CandidateRejectionFormDef) => void;
  onCancel: () => void;
};

export const RejectionModal = ({
  open,
  isFromRecommendedStatus,
  onReject,
  onCancel,
}: RejectionModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<CandidateRejectionFormDef>();
  const INITIAL_DATA: CandidateRejectionFormDef = {
    rejectionMessage: isFromRecommendedStatus ? undefined : t("job-application-rejection"),
    rejectionReason: undefined,
    rejectionReasonDescription: undefined,
  };
  const currentRejectionReason = useWatch("rejectionReason", form);

  const handleFinish = async (values: CandidateRejectionFormDef) => {
    onReject(values);
    form.resetFields();
  };

  const handleOnCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={INITIAL_DATA}
      name="rejection-form"
      data-hs-do-not-collect="true"
    >
      <Modal
        title={t("reject-candidate")}
        open={open}
        onOk={() => handleFinish(form.getFieldsValue())}
        onCancel={handleOnCancel}
        cancelText={t("Cancel")}
        okText={t("reject-candidate")}
        okType="danger"
      >
        {!isFromRecommendedStatus && (
          <Form.Item
            label={t("reject-candidate-textarea-title")}
            name="rejectionMessage"
            style={{ marginBottom: 32 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 8, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("reject-candidate-textarea-placeholder")}
            />
          </Form.Item>
        )}
        <Typography.Title level={5}>{t("rejection-reason-title")}</Typography.Title>
        <Form.Item
          label={t("rejection-reason-select-title")}
          name="rejectionReason"
          help={t("rejection-reason-help")}
          style={{ marginBottom: 16 }}
          wrapperCol={{ span: 24 }}
        >
          <SelectAutocomplete sortAlphabetically={false}>
            {Object.values(ECandidateRejectionReason).map((reason) => {
              return (
                <Select.Option key={reason} value={reason} label={t(reason)}>
                  {t(reason)}
                </Select.Option>
              );
            })}
          </SelectAutocomplete>
        </Form.Item>
        {currentRejectionReason === ECandidateRejectionReason.OTHER && (
          <Form.Item
            label={t("rejection-reason-other-title")}
            name="rejectionReasonDescription"
            style={{ marginBottom: 16 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 4 }}
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}
      </Modal>
    </Form>
  );
};
