import { getQuestionAnswer } from "@app/api/job-answers.api";
import { JobAnswerDef } from "@app/types/job-answers.types";
import {
  EJobQuestionAnswerType,
  JobQuestionDef,
  JobQuestionOptionDef,
} from "@app/types/job-questions.types";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { allowWordBreak } from "../../text.helper";
import styles from "./Answer.module.scss";

export interface AnswerProps {
  questionId: JobQuestionDef["id"];
  answerType: JobQuestionDef["answerType"];
  candidateUserId: string;
}

export const Answer = ({ questionId, answerType, candidateUserId }: AnswerProps) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState<JobAnswerDef | null>(null);

  useEffect(() => {
    const initState = async () => {
      try {
        setLoading(true);
        const res = await getQuestionAnswer(questionId, i18n.language, candidateUserId);
        setAnswer(res);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    initState();
  }, [questionId]);

  const getAnswerText = (answer: JobAnswerDef) => {
    if (answerType === EJobQuestionAnswerType.OPTION) {
      return (
        <ul className={styles.option}>
          {answer.options?.map((option: JobQuestionOptionDef) => (
            <li key={option.id}>{option.text}</li>
          ))}
        </ul>
      );
    }
    return <span>{allowWordBreak(answer.answer)}</span>;
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      {!answer && <span className={styles.emptyState}>{t("chat-no-answer")}</span>}
      {!!answer && getAnswerText(answer)}
    </div>
  );
};
