import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirebaseStorage } from "../../../hooks/useFirebaseStorage";

type UploaderFilesProps = {
  initialFiles?: UploadFile[];
  onChange?: (files: UploadFile[]) => void;
} & Omit<UploadProps, "fileList" | "onRemove" | "customRequest" | "onChange">;

export const UploaderFiles = ({
  initialFiles,
  multiple = true,
  showUploadList = true,
  listType = "picture",
  maxCount,
  onChange,
  ...rest
}: UploaderFilesProps) => {
  const { t } = useTranslation();
  const { uploadDocument } = useFirebaseStorage();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>(initialFiles || []);

  useEffect(() => {
    if (
      typeof onChange === "function" &&
      JSON.stringify(uploadedFiles) !== JSON.stringify(initialFiles)
    ) {
      onChange(uploadedFiles);
    }
  }, [uploadedFiles]);

  const uploaderProps: UploadProps = {
    multiple,
    maxCount,
    showUploadList,
    listType,
    fileList: uploadedFiles,
    customRequest: async (input) => {
      const { onError, onSuccess, file } = input;
      try {
        const result = await uploadDocument(file as RcFile, (file as RcFile).name);

        setUploadedFiles((prev) => {
          return [
            ...(maxCount === 1 ? [] : prev),
            {
              ...(file as RcFile),
              uid: (file as RcFile).uid,
              name: result.fileName,
              url: result.url,
              thumbUrl: result.url,
            },
          ];
        });
        if (onSuccess) onSuccess(null);
      } catch (e) {
        console.error(e);
        if (onError) onError(e as Error, null);
      }
    },
    onRemove: (file) => {
      setUploadedFiles(uploadedFiles.filter((f) => f.url !== file.url));
    },
    ...rest,
  };

  return (
    <Upload {...uploaderProps}>
      <Button type="primary" block ghost icon={<UploadOutlined />}>
        {t("Upload")}
      </Button>
    </Upload>
  );
};
