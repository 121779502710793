import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type FirstMessageModalProps = {
  open: boolean;
  hasError: boolean;
  onSend: (message: string) => void;
  onCancel: () => void;
};

const DEFAULT_MESSAGE = "job-application-first-message";

export const FirstMessageModal = ({ open, hasError, onSend, onCancel }: FirstMessageModalProps) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>(t(DEFAULT_MESSAGE));

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  return (
    <Modal
      title={t("send-first-message")}
      open={open}
      onOk={() => onSend(message.trim())}
      onCancel={onCancel}
      cancelText={t("Cancel")}
      okText={t("send-message")}
      okButtonProps={{ disabled: message.trim().length === 0 }}
      afterClose={() => setMessage(t(DEFAULT_MESSAGE))}
    >
      <label htmlFor="first-message">{t("send-first-message-textarea-title")}</label>
      <TextArea
        rows={8}
        id="first-message"
        style={{ marginTop: "10px" }}
        placeholder={t("send-first-message-textarea-placeholder")}
        value={message}
        onChange={handleMessageChange}
      />
      {hasError && <div className="chat-error-message">{t("unknown-error")}</div>}
    </Modal>
  );
};
