import { Button } from "@app/components/ui/Button/Button";
import { Breadcrumb, Col, Divider, Row, Space, Typography } from "antd";
import { ReactNode, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type ContentLayoutProps = {
  children: ReactNode;
  title?: string;
  breadcrumbs?: { title: string; url: string }[];
  headerItems?: ReactNode[];
  description?: string | ReactNode;
  nextButtonText?: string;
  nextButtonDisabled?: boolean;
  fullHeight?: boolean;
  compact?: boolean;
} & (
  | {
      nextRoute: string;
      nextButtonOnClick?: undefined | null;
      footer?: undefined | null;
    }
  | {
      nextButtonOnClick: () => void;
      nextRoute?: undefined | null;
      footer?: undefined | null;
    }
  | {
      footer: ReactNode;
      nextRoute?: undefined | null;
      nextButtonOnClick?: undefined | null;
    }
  | {
      footer?: ReactNode;
      nextRoute?: undefined | null;
      nextButtonOnClick?: undefined | null;
    }
);

export const ContentLayout = memo(function ContentLayout({
  children,
  title,
  breadcrumbs,
  headerItems,
  description,
  nextRoute,
  nextButtonText,
  nextButtonOnClick,
  nextButtonDisabled,
  footer,
  fullHeight,
  compact,
}: ContentLayoutProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const hideNextButton = typeof nextButtonOnClick !== "function" && !nextRoute;

  const handleOnClick = () => {
    if (typeof nextButtonOnClick === "function") {
      nextButtonOnClick();
    } else if (nextRoute) {
      navigate(nextRoute);
    }
  };

  if (fullHeight) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(!!title || !!headerItems) && (
          <div style={{ overflow: "hidden", padding: compact ? "15px 40px 0" : "40px 40px 0" }}>
            <Row gutter={16}>
              {!!title && (
                <Col flex="0 1 500px">
                  <Typography.Title level={3} style={{ ...(compact && { marginBottom: 0 }) }}>
                    {title}
                  </Typography.Title>
                  {!!description && description}
                </Col>
              )}
              {!!headerItems && (
                <Col flex="auto">
                  <Row justify="end" align="middle" wrap gutter={[8, 8]}>
                    {headerItems.map((item, index) => (
                      <Col key={index}>{item}</Col>
                    ))}
                  </Row>
                </Col>
              )}
              <Divider
                style={{
                  marginTop: compact ? "15px" : "40px",
                }}
              />
            </Row>
          </div>
        )}
        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>{children}</div>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      {breadcrumbs?.length && (
        <Breadcrumb>
          {breadcrumbs.map((item) => (
            <Breadcrumb.Item key={item.url}>
              <Link to={item.url}>{item.title}</Link>
            </Breadcrumb.Item>
          ))}
          {/* Empty breadcrumb item to force last separator to appear */}
          <Breadcrumb.Item>{` `}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      {(!!title || !!headerItems) && (
        <Row gutter={16}>
          {!!title && (
            <Col flex="0 1 600px">
              <Typography.Title level={3}>{title}</Typography.Title>
              {!!description && description}
            </Col>
          )}
          {!!headerItems && (
            <Col flex="auto" style={{ paddingTop: 40 }}>
              <Row justify="end" align="middle" wrap gutter={[8, 8]}>
                {headerItems.map((item, index) => (
                  <Col key={index}>{item}</Col>
                ))}
              </Row>
            </Col>
          )}
          <Divider />
        </Row>
      )}
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>{children}</Row>

      {(!hideNextButton || footer) && <Divider style={{ marginTop: 60 }} />}

      <Space />

      {footer && <Row>{footer}</Row>}

      {!hideNextButton && (
        <Row justify="end">
          <Col>
            <Button
              style={{ marginRight: "2rem" }}
              onClick={handleOnClick}
              type="primary"
              size="large"
              disabled={nextButtonDisabled}
            >
              {nextButtonText || t("Next")}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
});
