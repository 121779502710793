import { UniqueIdentifier } from "@dnd-kit/core";
import {
  AnimateLayoutChanges,
  SortableContext,
  defaultAnimateLayoutChanges,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useVirtualizer } from "@tanstack/react-virtual";
import React from "react";
import { KanbanProps } from "../../Kanban";
import { Container, ContainerProps } from "../Container/Container";
import { SortableItem, SortableItemProps } from "../SortableItem/SortableItem";
import styles from "./DroppableContainer.module.scss";

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export function DroppableContainer({
  columns = 1,
  disabled,
  id,
  items,
  style,
  strategy,
  renderItem,
  itemStyle,
  itemWrapperStyle,
  getIndex,
  ...props
}: ContainerProps &
  Pick<KanbanProps, "strategy" | "renderItem"> & {
    disabled?: boolean;
    id: UniqueIdentifier;
    items: UniqueIdentifier[];
    style?: React.CSSProperties;
    getIndex: (id: UniqueIdentifier) => number;
    itemStyle: SortableItemProps["style"];
    itemWrapperStyle: SortableItemProps["wrapperStyle"];
  }) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { active, isDragging, over, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") || items.includes(over.id)
    : false;

  const virtualizedItems = virtualizer.getVirtualItems();

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      columns={columns}
      noUlWrapper
      {...props}
    >
      <div ref={parentRef} className={styles.outerContainer}>
        <div
          className={styles.innerContainer}
          style={{
            height: virtualizer.getTotalSize(),
          }}
        >
          <div
            className={styles.list}
            style={{
              transform: `translateY(${virtualizedItems[0]?.start ?? 0}px)`,
            }}
          >
            <ul>
              <SortableContext items={items} strategy={strategy}>
                {virtualizedItems.map((virtualRow) => (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                  >
                    <SortableItem
                      key={virtualRow.key}
                      id={items[virtualRow.index]}
                      index={virtualRow.index}
                      style={itemStyle}
                      wrapperStyle={itemWrapperStyle}
                      renderItem={renderItem}
                      containerId={id}
                      getIndex={getIndex}
                    />
                  </div>
                ))}
              </SortableContext>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
}
