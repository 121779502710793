import { Colors } from "@app/constants/colors.constants";
import { RootState } from "@app/store/store";
import { ECandidateStatus } from "@app/types/candidate.types";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdAdjust, MdArrowCircleRight, MdCancel, MdPending } from "react-icons/md";
import { useSelector } from "react-redux";
import styles from "./CandidateStatusIcon.module.scss";

type CandidateStatusIconProps = {
  candidateStatusName?: string;
};
export const CandidateStatusIcon = ({ candidateStatusName }: CandidateStatusIconProps) => {
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);

  const getCandidateStatusIcon = (status: ECandidateStatus) => {
    switch (status) {
      case ECandidateStatus.PENDING:
        return { icon: MdPending, color: Colors.darkGray };
      case ECandidateStatus.ACCEPTED:
        return { icon: MdArrowCircleRight, color: Colors.primary };
      case ECandidateStatus.REJECTED:
        return { icon: MdCancel, color: Colors.red };
      case ECandidateStatus.HIRED:
        return { icon: IoIosCheckmarkCircle, color: Colors.green };
      case ECandidateStatus.CUSTOM:
      default:
        return { icon: MdAdjust, color: Colors.primary };
    }
  };

  const foundStatus = candidateStatuses.find((item) => item.name === candidateStatusName);
  const statusIconData = getCandidateStatusIcon(foundStatus?.status || ECandidateStatus.CUSTOM);
  const StatusIcon = statusIconData.icon;
  return <StatusIcon className={styles.icon} style={{ color: statusIconData.color }} />;
};
