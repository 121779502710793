import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { SPOKEN_LANGUAGES } from "@app/constants/stat.constants";
import {
  editUserStatsSA,
  getUserStatsSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import {
  mapStatsToApi,
  mapStatsToForm,
} from "@app/features/super-admin/helpers/super-admin.helper";
import {
  ALL_STATS_WITH_NUMBERS,
  ECraftsmanStatTypes,
  StatUnitMap,
  UserFormDef,
  UserStatsFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend, getLangName } from "@app/helpers/language.helper";
import { Col, Form, InputNumber, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type UserStatsProps = {
  onChangedValues: (changed: boolean) => void;
  selectedUser?: UserFormDef;
};

export const UserStats = ({ onChangedValues, selectedUser }: UserStatsProps) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [statsId, setStatsId] = useState<string>();
  const [form] = useForm<UserStatsFormDef>();

  const fetchData = async () => {
    if (selectedUser?.id) {
      setLoading(true);
      try {
        const data = await getUserStatsSA(selectedUser.id);
        setStatsId(data.data.id);
        form.setFieldsValue(mapStatsToForm(data.data.data));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    onChangedValues(false);
  }, []);

  const onSubmitForm = async (values: UserStatsFormDef) => {
    if (statsId && selectedUser) {
      setLoadingSave(true);
      try {
        editUserStatsSA({
          ...mapStatsToApi(values),
          id: statsId,
          profileId: selectedUser.id,
        });
        onChangedValues(false);
        message.success({ content: t("Saved!"), key: "user-stats", duration: 1 });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-stats",
            duration: 10,
          });
        }
      } finally {
        setLoadingSave(false);
      }
    }
  };

  const handleOnFormChange = () => {
    onChangedValues(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmitForm}
      onChange={handleOnFormChange}
      data-hs-do-not-collect="true"
    >
      {loading && <LoadingSpinner />}
      <Row gutter={[32, 32]}>
        {ALL_STATS_WITH_NUMBERS.map((item) => (
          <Col key={item.name} span={12}>
            <Form.Item label={t(item.label)} name={item.name} style={{ width: "100%" }}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                addonAfter={StatUnitMap[item.label] && t(StatUnitMap[item.label])}
              />
            </Form.Item>
          </Col>
        ))}
        <Col span={12}>
          <Form.Item label={t(ECraftsmanStatTypes.SPOKEN_LANGUAGES_STAT)} name="languages">
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Select")}
              options={SPOKEN_LANGUAGES.map((langCode) => ({
                value: convertLangForBackend(langCode),
                label: getLangName(i18n.language, convertLangForBackend(langCode)),
              }))}
              onChange={handleOnFormChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block loading={loadingSave}>
                  {t("Save")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
