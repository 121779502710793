import { DateFormats } from "@app/constants/date.constants";
import { useCandidateApprovalsSA } from "@app/features/super-admin/api/super-admin.approvals.api";
import { ECandidateApprovalAction } from "@app/types/candidate.types";
import { Alert, Card, Drawer, Space, Spin, Tag } from "antd";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

type ApprovalHistoryDrawerProps = {
  onClose: () => void;
  open: boolean;
  candidateUserId: string;
};

export const ApprovalHistoryDrawer = ({
  onClose,
  open,
  candidateUserId,
}: ApprovalHistoryDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer open={open} onClose={onClose} title={t("Approval History")}>
      <ApprovalHistory candidateUserId={candidateUserId} />
    </Drawer>
  );
};

const ApprovalHistory = ({ candidateUserId }: { candidateUserId: string }) => {
  const { t } = useTranslation();

  const approvalsQuery = useCandidateApprovalsSA({
    workerProfileId: candidateUserId,
  });

  return (
    <Space direction="vertical" size="small" style={{ width: "100%" }}>
      {approvalsQuery.isError && <Alert type="error" message={t("Sorry, something went wrong.")} />}
      {approvalsQuery.isLoading && <Spin />}
      {!approvalsQuery.isLoading && approvalsQuery.data?.count === 0 && (
        <Alert message="No history yet"></Alert>
      )}
      {approvalsQuery.data?.data.map((approval) => {
        return (
          <Card
            key={approval.id}
            size="small"
            title={approval.candidate.crafthuntCompany.name}
            extra={
              approval.action === ECandidateApprovalAction.APPROVE ? (
                <Tag color="green">Approved</Tag>
              ) : (
                <Tag color="volcano">Rejected</Tag>
              )
            }
          >
            <Card.Meta
              title={approval.candidate.jobAds?.map((jobAd) => jobAd.title).join(", ")}
              description={
                <Space direction="vertical">
                  {approval.rejectionReason && t(approval.rejectionReason)}
                  {approval.description}
                  <div>{moment(approval.createdAt).format(DateFormats.FULL_DATE_TIME)}</div>
                </Space>
              }
            />
          </Card>
        );
      })}
    </Space>
  );
};
